import { StyledComponent } from '@emotion/styled'
import { Box, Breadcrumbs, Button, Icon, Link, Skeleton, Typography } from '@mui/material'
import { styled } from '@mui/system'
import { GoogleIcon } from 'components/Svg'
import { AlignItems, ButtonVariant, JustifyContent } from 'components/utils/enums'
import {
  ClickableBoxProps,
  ImageProps,
  LabelWithAlignProps,
  LabelWithoutMarginProps,
  ModalContainerProps,
  RightContainerProps,
  RoutingContainerProps,
  RowProps,
  SkeletonAvatarProps,
  SkeletonImageProps,
  SkeletonRectangleProps,
  SkeletonTextProps,
} from 'components/utils/types'
import isUndefined from 'lodash/isUndefined'
import { pxToRem } from 'theme/typography'
import { ButtonTypes } from 'theme/utils/enums'
import {
  BOTTOM_FOOTER_HEIGHT_DESKTOP,
  BOTTOM_MENU_HEIGHT_MOBILE,
  HEADER_HEIGHT_DESKTOP,
  HEADER_HEIGHT_MOBILE,
  SIDE_MENU_WIDTH,
} from 'utils/constants'

const desktopHeight = HEADER_HEIGHT_DESKTOP + BOTTOM_FOOTER_HEIGHT_DESKTOP
const mobileHeight = HEADER_HEIGHT_MOBILE + BOTTOM_MENU_HEIGHT_MOBILE

export const CustomBreadcrumbs: StyledComponent<any> = styled(Breadcrumbs)(({ theme }) =>
  theme.unstable_sx({
    display: { xs: 'none', lg: 'flex '},
  })
)

export const Row: StyledComponent<any> = styled(Box, {
  shouldForwardProp: (prop) => 
    prop !== 'marginTop'
    && prop !== 'marginBottom'
    && prop !== 'paddingTop'
    && prop !== 'paddingBottom'
    && prop !== 'width'
    && prop !== 'height'
    && prop !== 'isColumnDirection'
    && prop !== 'alignItems'
    && prop !== 'justifyContent'
    && prop !== 'groupedByColumns'
    && prop !== 'groupedByTemplate'
    && prop !== 'gridGap'
    && prop !== 'gridRowGap'
    && prop !== 'gridColumnGap'
    && prop !== 'isTable'
})<RowProps>(({
    theme,
    marginTop,
    marginBottom,
    paddingTop,
    paddingBottom,
    width,
    height,
    isColumnDirection,
    alignItems,
    justifyContent,
    groupedByColumns,
    groupedByTemplate,
    gridGap,
    gridRowGap,
    gridColumnGap,
  }) =>
  theme.unstable_sx({
    mt: marginTop,
    mb: marginBottom,
    pt: paddingTop,
    pb: paddingBottom,
    display: `${!!groupedByColumns || !!groupedByTemplate ? 'grid' : 'flex'}`,
    alignItems: alignItems || `${AlignItems.center}`,
    flexDirection: isColumnDirection ? 'column' : 'row',
    justifyContent: justifyContent || `${JustifyContent.flexStart}`,
    width: width || '100%',
    ...(!isUndefined(height) && {
      height,
    }),
    ...(!isUndefined(groupedByColumns) && {
      gridTemplateColumns: `repeat(${groupedByColumns}, 1fr)`,
    }),
    ...(!isUndefined(groupedByTemplate) && {
      gridTemplateColumns: groupedByTemplate,
    }),
    ...(!isUndefined(gridGap) && {
      gridGap: typeof gridGap === 'number' ? pxToRem(gridGap) : gridGap,
    }),
    ...(!isUndefined(gridRowGap) && {
      gridRowGap: typeof gridRowGap === 'number' ? pxToRem(gridRowGap) : gridRowGap,
    }),
    ...(!isUndefined(gridColumnGap) && {
      gridColumnGap: typeof gridColumnGap === 'number' ? pxToRem(gridColumnGap) : gridColumnGap,
    }),
    ...((!isUndefined(groupedByColumns) || !isUndefined(groupedByTemplate))
      && isUndefined(gridGap) && isUndefined(gridRowGap) && isUndefined(gridColumnGap) && {
      gridGap: pxToRem(8),
    }),
    '&:last-of-type': {
      mb: 0,
    }
  })
)

export const Image: StyledComponent<any> = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== 'width'
    && prop !== 'height'
    && prop !== 'fullCover'
    && prop !== 'borderTopLeftRadius'
    && prop !== 'borderTopRightRadius'
    && prop !== 'borderBottomLeftRadius'
    && prop !== 'borderBottomRightRadius',
})<ImageProps>(({ theme, width, height, size, fullCover, borderTopLeftRadius, borderTopRightRadius, borderBottomLeftRadius, borderBottomRightRadius }) =>
  theme.unstable_sx({
    width: size || width,
    height: size || height,
    display: 'block',
    overflow: 'hidden',
    ...(!isUndefined(fullCover) && {
      objectFit: 'cover',
    }),
    ...(!isUndefined(borderTopLeftRadius) && {
      borderTopLeftRadius: pxToRem(borderTopLeftRadius),
    }),
    ...(!isUndefined(borderTopRightRadius) && {
      borderTopRightRadius: pxToRem(borderTopRightRadius),
    }),
    ...(!isUndefined(borderBottomLeftRadius) && {
      borderBottomLeftRadius: pxToRem(borderBottomLeftRadius),
    }),
    ...(!isUndefined(borderBottomRightRadius) && {
      borderBottomRightRadius: pxToRem(borderBottomRightRadius),
    }),
  })
)
Image.defaultProps = {
  component: 'img',
}

export const RootContainer: StyledComponent<any> = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    justifyContent: `${JustifyContent.center}`
  })
)

export const RightContainer: StyledComponent<any> = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isSignedIn',
})<RightContainerProps>(({ theme, isSignedIn }) =>
  theme.unstable_sx({
    display: 'flex',
    flexDirection: 'column',
    width: { xs: '100%', lg: isSignedIn ? `calc(100% - ${pxToRem(SIDE_MENU_WIDTH)})` : '100%' },
  })
)

export const BodyContainer: StyledComponent<any> = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isSignedIn',
})<RightContainerProps>(({ theme, isSignedIn }) =>
  theme.unstable_sx({
    display: 'flex',
    flexDirection: 'column',
    alignItems: `${AlignItems.baseline}`,
    width: {
      xs: '100%',
      md: isSignedIn ? '100%' : pxToRem(theme.breakpoints.values.md),
      lg: isSignedIn ? '100%' : pxToRem(theme.breakpoints.values.lg),
      xl: isSignedIn ? '100%' : pxToRem(theme.breakpoints.values.xl)
    },
    height: '100%',
  })
)

export const SpinnerContainer: StyledComponent<any> = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    width: '100%',
    display: 'flex',
    justifyContent: `${JustifyContent.center}`,
  }),
)

export const RoutingContainer: StyledComponent<any> = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isLoggedUser',
})<RoutingContainerProps>(({ theme, isLoggedUser }) =>
  theme.unstable_sx({
    mt: { xs: pxToRem(HEADER_HEIGHT_MOBILE), lg: 0 },
    mb: { xs: pxToRem(BOTTOM_MENU_HEIGHT_MOBILE), lg: 0 },
    px: { xs: pxToRem(8), md: pxToRem(16) },
    py: { xs: pxToRem(8), md: pxToRem(16) },
    position: 'relative',
    width: { xs: '100%', lg: '100%' },
    display: 'flex',
    alignItems: `${AlignItems.baseline}`,
    justifyContent: `${JustifyContent.center}`,
    height: isLoggedUser
      ? { xs: `calc(100% - ${pxToRem(mobileHeight)})`, lg: '100%' }
      : { xs: `calc(100% - ${pxToRem(mobileHeight)})`, lg: `calc(100% - ${pxToRem(desktopHeight)})` },
    overflowY: 'auto',
    backgroundColor: isLoggedUser
      ? { xs: theme.palette.grey[100], s: theme.palette.grey[100], md: theme.palette.grey[100], lg: theme.palette.grey[100] }
      : { },
  })
)

export const CarouselTopBottomContainer: StyledComponent<any> = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    px: pxToRem(8),
    display: 'flex',
    alignItems: `${AlignItems.center}`,
    height: pxToRem(32),
    bgcolor: theme.palette.blue.main,
  })
)

export const CarouselHeaderContainer: StyledComponent<any> = styled(CarouselTopBottomContainer)(({ theme }) =>
  theme.unstable_sx({
    mb: 0,
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
  })
)

export const CarouselFooterContainer: StyledComponent<any> = styled(CarouselTopBottomContainer)(({ theme }) =>
  theme.unstable_sx({
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
  })
)

export const MachineryFilterDropdownsContainer: StyledComponent<any> = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    width: '100%',
    display: { xs: 'flex', lg: 'grid' },
    flexDirection: { xs: 'column', lg: 'row' },
    alignItems: { xs: 'baseline', lg: 'center' },
    justifyContent: `${JustifyContent.center}`,
    gridTemplateColumns: { xs: undefined, lg: 'repeat(3, 1fr)' },
    gridGap: { xs: undefined, lg: pxToRem(16) },
  })
)

export const LabelWithAlign: StyledComponent<any> = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'textAlign',
})<LabelWithAlignProps>(({ theme, textAlign }) =>
  theme.unstable_sx({
    width: '100%',
    textAlign: `${textAlign}`,
  })
)

export const LabelWithoutMargin: StyledComponent<any> = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'ellipsis',
})<LabelWithoutMarginProps>(({ theme, ellipsis }) =>
  theme.unstable_sx({
    marginBottom: { xs: 0, sm: 0, md: 0, lg: 0, xl: 0 },
    ...(!isUndefined(ellipsis) && {
      textAlign: 'left',
      display: '-webkit-box',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      WebkitLineClamp: 1,
      WebkitBoxOrient: 'vertical',
    }),
  })
)

export const MachinerySortAndFilterButtonContainer: StyledComponent<any> = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: { xs: 'flex', lg: 'grid' },
    flexDirection: { xs: 'column', lg: 'unset' },
    gridTemplateColumns: { xs: undefined, lg: `1fr ${pxToRem(240)}` },
    gridGap: { xs: undefined, lg: pxToRem(16) },
    width: '100%',
  })
)

export const ThumbnailCard: StyledComponent<any> = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    m: 0,
    p: 0,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderWidth: pxToRem(1),
    borderStyle: 'solid',
    borderColor: theme.palette.app.border,
    borderRadius: pxToRem(8),
    overflow: 'hidden',
  })
)

export const ThumbnailPhotoContainer: StyledComponent<any> = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    m: 0,
    p: 0,
    width: '100%',
    height: pxToRem(208),
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    borderTopLeftRadius: pxToRem(8),
    borderTopRightRadius: pxToRem(8),
  })
)

export const ThumbnailPhoto: StyledComponent<any> = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  })
)
ThumbnailPhoto.defaultProps = {
  component: 'img',
}

export const ThumbnailPriceContainer: StyledComponent<any> = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    px: pxToRem(8),
    py: pxToRem(4),
    position: 'absolute',
    bottom: 0,
    right: 0,
    display: 'flex',
    alignItems: `${AlignItems.center}`,
    justifyContent: `${JustifyContent.flexEnd}`,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.orange.main,
    width: { xs: pxToRem(144), md: pxToRem(160), lg: pxToRem(160) },
  })
)
export const ThumbnailBodyContainer: StyledComponent<any> = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    p: pxToRem(16),
  })
)

export const ThumbnailBodyReference: StyledComponent<any> = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    width: '100%',
    textAlign: 'center',
  })
)

export const ThumbnailBodyItem: StyledComponent<any> = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    width: '100%',
    height: pxToRem(20),
    textAlign: 'left',
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
  })
)

export const ThumbnailBodyDescription: StyledComponent<any> = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    mx: 0,
    mt: 0,
    mb: pxToRem(8),
    pb: pxToRem(8),
    width: '100%',
    height: { xs: pxToRem(90), md: pxToRem(103) },
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitLineClamp: 6,
    WebkitBoxOrient: 'vertical',
    borderBottomWidth: pxToRem(1),
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.app.border,
    boxSizing: 'content-box',
  })
)

export const ThumbnailFooterButtonContainer: StyledComponent<any> = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    width: '100%',
    height: pxToRem(32),
    display: 'flex',
    alignItems: `${AlignItems.center}`,
    justifyContent: `${JustifyContent.center}`,
    borderRadius: 0,
    '&:first-of-type': {
      color: theme.palette.app.machineThumbnail.actionButtons.link.contrastText,
      backgroundColor: theme.palette.app.machineThumbnail.actionButtons.link.main,
      '&.machine-details': {
        borderTopLeftRadius: pxToRem(8),
        borderBottomLeftRadius: pxToRem(8),
      },
    },
    '&:nth-of-type(2)': {
      color: theme.palette.app.machineThumbnail.actionButtons.contact.contrastText,
      backgroundColor: theme.palette.app.machineThumbnail.actionButtons.contact.main,
    },
    '&:nth-of-type(3)': {
      color: theme.palette.app.machineThumbnail.actionButtons.whatsApp.contrastText,
      backgroundColor: theme.palette.app.machineThumbnail.actionButtons.whatsApp.main,
    },
    '&:last-of-type': {
      color: theme.palette.app.machineThumbnail.actionButtons.share.contrastText,
      backgroundColor: theme.palette.app.machineThumbnail.actionButtons.share.main,
      '&.machine-details': {
        borderTopRightRadius: pxToRem(8),
        borderBottomRightRadius: pxToRem(8),
      },
    },
  })
)

export const ActionLink: StyledComponent<any> = styled(Link)(({ theme }) =>
  theme.unstable_sx({
  })
)
ActionLink.defaultProps = {
  variant: 'caption',
  target: '_blank',
  rel: 'noopener',
}

export const ActionButton: StyledComponent<any> = styled(Button)(({ theme }) =>
  theme.unstable_sx({
    minWidth: 'unset',
    color: theme.palette.common.white,
  })
)
ActionButton.defaultProps = {
  type: ButtonTypes.text,
}

export const ModalContainer: StyledComponent<any> = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'width' && prop !== 'height',
})<ModalContainerProps>(({ theme, width, height }) =>
  theme.unstable_sx({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width,
    ...(!isUndefined(height) && {
      height,
    }),
    borderWidth: pxToRem(1),
    borderStyle: 'solid',
    borderColor: theme.palette.app.border,
    borderRadius: pxToRem(8),
    overflow: 'hidden',
  })
)

export const ModalHeader: StyledComponent<any> = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    px: pxToRem(8),
    py: pxToRem(4),
    display: 'flex',
    alignItems: `${AlignItems.center}`,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.blue.light,
    width: '100%',
  })
)

export const ModalBody: StyledComponent<any> = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    p: pxToRem(8),
    display: 'flex',
    alignItems: `${AlignItems.flexStart}`,
    justifyContent: `${JustifyContent.flexStart}`,
    flexDirection: 'column',
    color: theme.palette.common.black,
    backgroundColor: theme.palette.common.white,
    width: '100%',
  })
)

export const ModalFooter: StyledComponent<any> = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    p: pxToRem(8),
    display: 'flex',
    alignItems: `${AlignItems.flexStart}`,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.blue.light,
    width: '100%',
  })
)

export const SocialMediaButtonsContainer: StyledComponent<any> = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    flexDirection: 'column',
    alignItems: `${AlignItems.center}`,
    justifyContent: `${JustifyContent.center}`,
    width: '100%',
  })
)

export const SocialMediaButton: StyledComponent<any> = styled(Button)(({ theme }) =>
  theme.unstable_sx({
    px: pxToRem(16),
    py: pxToRem(4),
    minWidth: 'unset',
    color: theme.palette.common.white,
    width: '70%',
    boxSizing: 'content-box',
    '&:not(:last-of-type)': {
      mb: pxToRem(16),
    },
  })
)
SocialMediaButton.defaultProps = {
  type: ButtonTypes.primary,
  variant: ButtonVariant.contained,
}

export const MachineCarouselContainer: StyledComponent<any> = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    width: '100%',
    justifyContent: `${JustifyContent.center}`,
    borderTopLeftRadius: pxToRem(8),
    borderTopRightRadius: 0,
    borderBottomLeftRadius: pxToRem(8),
    borderWidth: pxToRem(1),
    borderStyle: 'solid',
    borderColor: theme.palette.app.border,
    overflow: 'hidden',
    '&.mobile': {
      borderTopRightRadius: pxToRem(8),
      borderBottomLeftRadius: 0,
    },
  })
)

export const MachineMainDetailsContainer: StyledComponent<any> = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    padding: `${pxToRem(8)} ${pxToRem(16)}`,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: pxToRem(418),
    justifyContent: `${JustifyContent.flexStart}`,
    borderTopLeftRightRadius: 0,
    borderTopRightRadius: pxToRem(8),
    borderBottomRightRadius: pxToRem(8),
    borderWidth: pxToRem(1),
    borderTopStyle: 'solid',
    borderLeftStyle: 'none',
    borderRightStyle: 'solid',
    borderBottomStyle: 'solid',
    borderColor: theme.palette.app.border,
    overflow: 'hidden',
    '&.tablet': {
      height: pxToRem(338),
    },
    '&.mobile': {
      height: 'auto',
      borderTopStyle: 'none',
      borderLeftStyle: 'solid',
      borderTopLeftRightRadius: pxToRem(8),
      borderTopRightRadius: 0,
      borderBottomLeftRadius: pxToRem(8),
    },
  })
)

export const SkeletonText: StyledComponent<any> = styled(Skeleton, {
  shouldForwardProp: (prop) => prop !== 'fontSize',
})<SkeletonTextProps>(({ theme, fontSize }) =>
  theme.unstable_sx({
    width: '100%',
    ...(!isUndefined(fontSize) && {
      fontSize,
    }),
    ...(isUndefined(fontSize) && {
      fontSize: 'inherit',
    }),
  })
)
SkeletonText.defaultProps = {
  variant: 'text',
}

export const SekeletonAvatar: StyledComponent<any> = styled(Skeleton, {
  shouldForwardProp: (prop) => prop !== 'size',
})<SkeletonAvatarProps>(({ theme, size }) =>
  theme.unstable_sx({
    ...(!isUndefined(size) && {
      width: size,
      height: size,
    }),
    ...(isUndefined(size) && {
      width: pxToRem(32),
      height: pxToRem(32),
    }),
  })
)
SekeletonAvatar.defaultProps = {
  variant: 'circular',
}

export const SkeletonRectangle: StyledComponent<any> = styled(Skeleton, {
  shouldForwardProp: (prop) => prop !== 'mx' && prop !== 'my' && prop !== 'width' && prop !== 'height'
})<SkeletonRectangleProps>(({ theme, mx, my, width, height }) =>
  theme.unstable_sx({
    mx: isUndefined(mx) ? 0 : pxToRem(mx),
    my: isUndefined(my) ? 0 : pxToRem(my),
    width: width || '100%',
    height: height || '100%',
  })
)
SkeletonRectangle.defaultProps = {
  variant: 'rectangle',
}

export const SkeletonImage: StyledComponent<any> = styled(Skeleton, {
  shouldForwardProp: (prop) =>
    prop !== 'width'
    && prop !== 'height'
    && prop !== 'borderRadius'
    && prop !== 'borderTopRadius'
    && prop !== 'borderBottomRadius'
    && prop !== 'borderLeftRadius'
    && prop !== 'borderRightRadius'
    && prop !== 'borderTopLeftRadius'
    && prop !== 'borderTopRightRadius'
    && prop !== 'borderBottomLeftRadius'
    && prop !== 'borderBottomRightRadius',
})<SkeletonImageProps>(({
    theme,
    width,
    height,
    borderRadius,
    borderTopRadius,
    borderBottomRadius,
    borderLeftRadius,
    borderRightRadius,
    borderTopLeftRadius,
    borderTopRightRadius,
    borderBottomLeftRadius,
    borderBottomRightRadius,
  }) =>
  theme.unstable_sx({
    width: width || '100%',
    height: height || '100%',
    ...(!isUndefined(borderRadius) && {
      borderRadius: pxToRem(borderRadius),
    }),
    ...(!isUndefined(borderTopRadius) && {
      borderTopLeftRadius: pxToRem(borderTopRadius),
      borderTopRightRadius: pxToRem(borderTopRadius),
    }),
    ...(!isUndefined(borderBottomRadius) && {
      borderBottomLeftRadius: pxToRem(borderBottomRadius),
      borderBottomRightRadius: pxToRem(borderBottomRadius),
    }),
    ...(!isUndefined(borderLeftRadius) && {
      borderTopLeftRadius: pxToRem(borderLeftRadius),
      borderBottomLeftRadius: pxToRem(borderLeftRadius),
    }),
    ...(!isUndefined(borderRightRadius) && {
      borderTopRightRadius: pxToRem(borderRightRadius),
      borderBottomRightRadius: pxToRem(borderRightRadius),
    }),
    ...(!isUndefined(borderTopLeftRadius) && {
      borderTopLeftRadius: pxToRem(borderTopLeftRadius),
    }),
    ...(!isUndefined(borderTopRightRadius) && {
      borderTopRightRadius: pxToRem(borderTopRightRadius),
    }),
    ...(!isUndefined(borderBottomLeftRadius) && {
      borderBottomLeftRadius: pxToRem(borderBottomLeftRadius),
    }),
    ...(!isUndefined(borderBottomRightRadius) && {
      borderBottomRightRadius: pxToRem(borderBottomRightRadius),
    }),
  })
)
SkeletonImage.defaultProps = {
  variant: 'rounded',
}

export const GoogleButton: StyledComponent<any> = styled(Button)(({ theme }) =>
  theme.unstable_sx({
    color: theme.palette.common.black,
    borderColor: theme.palette.common.black,
    width: { xs: pxToRem(200), sm: pxToRem(300) },
    height: { xs: 'auto' }
  })
)

export const GoogleIconStyle: StyledComponent<any> = styled(GoogleIcon)(({ theme }) =>
  theme.unstable_sx({
    mr: pxToRem(8),
  })
)

export const ClickableIcon: StyledComponent<any> = styled(Icon)(({ theme }) =>
  theme.unstable_sx({
    cursor: 'pointer',
  })
)

export const ClickableBox: StyledComponent<any> = styled(Box, {
  shouldForwardProp: (prop) => 
    prop !== 'width'
    && prop !== 'height'
    && prop !== 'bgColor'
    && prop !== 'disabled'
    && prop !== 'borderWidth'
    && prop !== 'borderStyle'
    && prop !== 'borderColor'
    && prop !== 'borderTopWidth'
    && prop !== 'borderTopStyle'
    && prop !== 'borderTopColor'
    && prop !== 'borderBottomWidth'
    && prop !== 'borderBottomStyle'
    && prop !== 'borderBottomColor'
    && prop !== 'borderLeftWidth'
    && prop !== 'borderLeftStyle'
    && prop !== 'borderLeftColor'
    && prop !== 'borderRightWidth'
    && prop !== 'borderRightStyle'
    && prop !== 'borderRightColor'
    && prop !== 'borderRadius'
    && prop !== 'borderTopRadius'
    && prop !== 'borderBottomRadius'
    && prop !== 'borderLeftRadius'
    && prop !== 'borderRightRadius'
    && prop !== 'borderTopLeftRadius'
    && prop !== 'borderTopRightRadius'
    && prop !== 'borderBottomLeftRadius'
    && prop !== 'borderBottomRightRadius'
})<ClickableBoxProps>(({
  theme,
  width,
  height,
  bgColor,
  disabled,
  borderWidth,
  borderStyle,
  borderColor,
  borderTopWidth,
  borderTopStyle,
  borderTopColor,
  borderBottomWidth,
  borderBottomStyle,
  borderBottomColor,
  borderLeftWidth,
  borderLeftStyle,
  borderLeftColor,
  borderRightWidth,
  borderRightStyle,
  borderRightColor,
  borderRadius,
  borderTopRadius,
  borderBottomRadius,
  borderLeftRadius,
  borderRightRadius,
  borderTopLeftRadius,
  borderTopRightRadius,
  borderBottomLeftRadius,
  borderBottomRightRadius,
}) =>
  theme.unstable_sx({
    px: pxToRem(8),
    display: 'flex',
    alignItems: AlignItems.center,
    justifyContent: JustifyContent.center,
    cursor: 'pointer',
    width: width || '100%',
    height: height || '100%',
    ...(!isUndefined(bgColor) && {
      backgroundColor: bgColor,
    }),
    ...(!isUndefined(disabled) && {
      opacity: 0.38,
    }),
    ...(!isUndefined(borderWidth) && {
      borderWidth: pxToRem(borderWidth),
    }),
    ...(!isUndefined(borderStyle) && {
      borderStyle,
    }),
    ...(!isUndefined(borderColor) && {
      borderColor,
    }),
    ...(!isUndefined(borderTopWidth) && {
      borderTopWidth: pxToRem(borderTopWidth),
    }),
    ...(!isUndefined(borderTopStyle) && {
      borderTopStyle,
    }),
    ...(!isUndefined(borderTopColor) && {
      borderTopColor,
    }),
    ...(!isUndefined(borderBottomWidth) && {
      borderBottomWidth: pxToRem(borderBottomWidth),
    }),
    ...(!isUndefined(borderBottomStyle) && {
      borderBottomStyle,
    }),
    ...(!isUndefined(borderBottomColor) && {
      borderBottomColor,
    }),
    ...(!isUndefined(borderLeftWidth) && {
      borderLeftWidth: pxToRem(borderLeftWidth),
    }),
    ...(!isUndefined(borderLeftStyle) && {
      borderLeftStyle,
    }),
    ...(!isUndefined(borderLeftColor) && {
      borderLeftColor,
    }),
    ...(!isUndefined(borderRightWidth) && {
      borderRightWidth: pxToRem(borderRightWidth),
    }),
    ...(!isUndefined(borderRightStyle) && {
      borderRightStyle,
    }),
    ...(!isUndefined(borderRightColor) && {
      borderRightColor,
    }),
    ...(!isUndefined(borderRadius) && {
      borderRadius: pxToRem(borderRadius),
    }),
    ...(!isUndefined(borderTopRadius) && {
      borderTopLeftRadius: pxToRem(borderTopRadius),
      borderTopRightRadius: pxToRem(borderTopRadius),
    }),
    ...(!isUndefined(borderBottomRadius) && {
      borderBottomLeftRadius: pxToRem(borderBottomRadius),
      borderBottomRightRadius: pxToRem(borderBottomRadius),
    }),
    ...(!isUndefined(borderLeftRadius) && {
      borderTopLeftRadius: pxToRem(borderLeftRadius),
      borderBottomLeftRadius: pxToRem(borderLeftRadius),
    }),
    ...(!isUndefined(borderRightRadius) && {
      borderTopRightRadius: pxToRem(borderRightRadius),
      borderBottomRightRadius: pxToRem(borderRightRadius),
    }),
    ...(!isUndefined(borderTopLeftRadius) && {
      borderTopLeftRadius: pxToRem(borderTopLeftRadius),
    }),
    ...(!isUndefined(borderTopRightRadius) && {
      borderTopRightRadius: pxToRem(borderTopRightRadius),
    }),
    ...(!isUndefined(borderBottomLeftRadius) && {
      borderBottomLeftRadius: pxToRem(borderBottomLeftRadius),
    }),
    ...(!isUndefined(borderBottomRightRadius) && {
      borderBottomRightRadius: pxToRem(borderBottomRightRadius),
    }),
  })
)

export const LabelSeparator: StyledComponent<any> = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    my: pxToRem(8),
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: theme.palette.border,
    width: '100%',
    maxWidth: { xs: pxToRem(240), sm: pxToRem(336) },
    fontSize: pxToRem(12),
    fontWeight: 'normal',
    '&::before': {
      content: '""',
      flex: 1,
      borderBottom: `1px solid ${theme.palette.app.border}`,
    },
    '&::after': {
      content: '""',
      flex: 1,
      borderBottom: `1px solid ${theme.palette.app.border}`,
    },
    '&:not(:empty)::before': {
      mr: pxToRem(8),
    },
    '&:not(:empty)::after': {
      ml: pxToRem(8),
    },
  })
)

export const ComingSoonContainer: StyledComponent<any> = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    flexDirection: 'column',
    alignItems: AlignItems.center,
    '& .MuiTypography-root.MuiTypography-body2': {
      mb: pxToRem(16),
    }
  })
)