import { useTheme } from '@mui/material/styles'
import { DropdownSingle, DynamicRow, EmailInput, MoneyInput, MultiTextBox, NumericInput, PasswordInput, PrimaryButton, StringInput, ToggleSwitch, UrlInput } from 'components/FormFields'
import { PhoneNumberInput } from 'components/FormFields/PhoneNumberInput'
import { LabelWithoutMargin, Row } from 'components/styledComponents'
import { AlignItems, JustifyContent, NotificationType } from 'components/utils/enums'
import { DropdownStructure } from 'components/utils/types'
import moment from 'moment'
import PageContainer from 'pages/components/PageContainer'
import { MACHINERY_DROPDOWN_BASED_ON_MACHINES } from 'pages/Machinery/utils/constants'
import { PUBLISH_MACHINE_FORM } from 'pages/utils/constants'
import { PublishMachineField } from 'pages/utils/enums'
import { validateEmptyValue } from 'pages/utils/libs'
import { PublishMachineForm } from 'pages/utils/types'
import React, { FC, useEffect, useState } from 'react'
import { DropzoneArea } from 'react-mui-dropzone'
import { connect } from 'react-redux'
import { showAlert } from 'redux/actions/alert'
import { getCountries } from 'redux/actions/countries'
import { getCategories, getManufacturers, getModels, publishMachine } from 'redux/actions/machinery'
import { getStates } from 'redux/actions/states'
import { checkUsernameAvailability } from 'redux/actions/users'
import { ShowAlertParams } from 'redux/utils/alerts.types'
import { CountriesResponse } from 'redux/utils/countries.types'
import { Language, PublishingType } from 'redux/utils/enums'
import { LanguageDictionary } from 'redux/utils/language.types'
import { CreateMachineParams, GetCategoriesParams, GetCategoriesResponse, GetManufactorersParams, GetManufacturersResponse, GetModelsParams, GetModelsResponse } from 'redux/utils/machinery.types'
import { GetStatesParams, StatesResponse } from 'redux/utils/states.types'
import { ReduxStore } from 'redux/utils/types'
import { pxToRem } from 'theme/typography'
import { DROPDOWN_UNSELECTED_OPTION, MAX_IMAGES_ALLOWED } from 'utils/constants'

type Props = {
  isAlertOpen: boolean
  alertMessage: string
  countries?: CountriesResponse[]
  countriesLoading?: boolean
  dictionary: LanguageDictionary
  language: Language
  categories?: GetCategoriesResponse
  categoriesLoading?: boolean
  manufacturers?: GetManufacturersResponse
  manufacturersLoading?: boolean
  models?: GetModelsResponse
  modelsLoading?: boolean
  isSignedIn: boolean
  states?: StatesResponse[],
  statesLoading?: boolean,
  dispatchCheckUsernameAvailability: (username: string) => void
  dispatchGetCategories: (params: GetCategoriesParams) => void
  dispatchGetCountries: () => void
  dispatchGetManufacturers: (params: GetManufactorersParams) => void
  dispatchGetModels: (params: GetModelsParams) => void
  dispatchGetStates: (params: GetStatesParams) => void
  dispatchPublishMachine: (params: CreateMachineParams, files: File[], imagesPath: string) => void
  dispatchShowAlert: (alertProps: ShowAlertParams) => void
}

const PublishMachine: FC<Props> = ({
  isAlertOpen,
  alertMessage,
  countries,
  countriesLoading,
  dictionary: {
    shared: sharedDictionary,
    error: errorDictionary,
    machinery: machineryDictionary,
    publishMachine: publishMachineDictionary
  },
  language,
  categories,
  categoriesLoading,
  manufacturers,
  manufacturersLoading,
  models,
  modelsLoading,
  isSignedIn,
  states,
  statesLoading,
  dispatchCheckUsernameAvailability,
  dispatchGetCategories,
  dispatchGetCountries,
  dispatchGetManufacturers,
  dispatchGetModels,
  dispatchGetStates,
  dispatchPublishMachine,
  dispatchShowAlert,
}) => {
  const { palette } = useTheme()
  const [formValues, setFormValues] = useState<PublishMachineForm>(PUBLISH_MACHINE_FORM)
  const [isMismatchError, setIsMismatchError] = useState<boolean>()
  const [categoriesOptions, setCategoriesOptions] = useState<DropdownStructure[]>([])
  const [manufacturersOptions, setManufacturersOptions] = useState<DropdownStructure[]>([])
  const [modelsOptions, setModelsOptions] = useState<DropdownStructure[]>([])
  const [files, setFiles] = useState<File[]>([])
  const [countriesOptions, setCountriesOptions] = useState<DropdownStructure[]>([])
  const [statesOptions, setStatesOptions] = useState<DropdownStructure[]>([])
  const [shouldResetUsername, setShouldResetUsername] = useState<boolean>(false)
  const [titleComplement, setTitleComplement] = useState<string | undefined>()

  const adornmentBgColor: string = palette.grey[300]
  const isCategoryDisabled: boolean = categoriesLoading || !categoriesOptions.length
  const isCategorySelected: boolean = formValues.categoryName.value !== '-1'
  const isManufacturerDisabled: boolean = !isCategorySelected || manufacturersLoading || !manufacturersOptions.length
  const isManufacturerSelected: boolean = formValues.categoryName.value !== '-1' && formValues.manufacturerName.value !== '-1'
  const isModelDisabled: boolean = !isManufacturerSelected || modelsLoading || !modelsOptions.length
  const isSale = [PublishingType.sale, PublishingType.both].includes(formValues.publishingType.value)
  const isRent = [PublishingType.rent, PublishingType.both].includes(formValues.publishingType.value)
  const initialYear: number = 1961
  const currentYear: number = Number(moment().format('YYYY'))
  const yearOptions: DropdownStructure[] = Array.from({ length: currentYear - initialYear + 1 }, (_, index) => index + initialYear)
    .sort((a, b) => b - a)
    .map(year => ({
      label: year.toString(),
      value: year.toString(),
    })
  )

  const publishingTypeOption: DropdownStructure[] = Object.keys(PublishingType).map(key => ({
    label: publishMachineDictionary[key],
    value: key,
  }))

  const handleUpdateFiles = (newFiles: File[]) => {
    setFiles(newFiles)
  }

  /**
   * Verifies if a provided username is in use or if it is available for sign-up.
   * @param username The username to be evaluated.
   */
  const handleValidateUsername = (username: string) => (hasErrors?: boolean) => {
    if (!username) {
      return
    }

    // Update the validity for the username.
    setFormValues(prevState => ({
      ...prevState,
      username: {
        ...prevState.username,
        valid: !hasErrors,
      },
    }))

    // Trigger the evaluation only when the user provided the username manually.
    dispatchCheckUsernameAvailability(username)
  }

  const handleValidateField = (fieldName: PublishMachineField) => (hasErrors?: boolean) => {
    // Update the validity for the username.
    setFormValues(prevState => ({
      ...prevState,
      [fieldName]: {
        ...prevState[fieldName],
        valid: !hasErrors,
      },
    }))
  }

  /**
   * Compares the Password with its Confirmation to validate both are the same.
   * @param password The provided password.
   * @param confirmPassword The value that need to match with the password to make sure the user provided the desired password.
   */
  const handleValidatePasswords = (password: string, confirmPassword: string): void => {
    const passwordMismatch: boolean = password !== confirmPassword

    setIsMismatchError(passwordMismatch)

    // Update the "valid" prop for the "Confirm Password" field.
    const newFormValues: PublishMachineForm = {
      ...formValues,
      confirmPassword: {
        ...formValues.confirmPassword,
        value: confirmPassword,
        valid: !passwordMismatch,
      },
    }

    // Update the values for the form.
    setFormValues(newFormValues)
  }

  /**
   * Updates a value into the form based on the provided field name.
   * @param fieldName The field to be updated within the form.
   */
  const handleFieldChange = (fieldName: PublishMachineField, isNumericValue?: boolean) => (newValue: string): void => {
    // Translate the value to number when the "isNumericValue" param is set as "TRUE".
    const fieldValue: string | number = isNumericValue ? Number(newValue) : newValue

    // Update the value for the provided fieldname.
    const newFormValues: PublishMachineForm = {
      ...formValues,
      [fieldName]: {
        ...formValues[fieldName],
        value: fieldValue,
      },
    }

    // Compare the password with its confirmation when the updated field is the "Confirm Password".
    if (fieldName === PublishMachineField.confirmPassword && typeof fieldValue === 'string') {
      handleValidatePasswords(formValues.password.value, fieldValue)
      return
    }

    // Update the values for the form.
    setFormValues(newFormValues)
  }

  /**
   * Triggers the passwords validation when the user blurs from the "Confirm Password" field.
   * @param password The provided password.
   * @param confirmPassword The value that need to match with the password to make sure the user provided the desired password.
   */
  const handleComparePasswords = (password: string, confirmPassword: string) => (): void => {
    handleValidatePasswords(password, confirmPassword)
  }

  /**
   * Updates a value into the form based on the provided field name.
   * @param fieldName The field to be updated within the form.
   */
  const handleDropdownChange = (fieldName: PublishMachineField, isNumericValue?: boolean) => (newValue: string): void => {
    // Translate the value to number when the "isNumericValue" param is set as "TRUE".
    const fieldValue: string | number = isNumericValue ? Number(newValue) : newValue

    // Update the value for the provided fieldname.
    const newFormValues: PublishMachineForm = {
      ...formValues,
      [fieldName]: {
        ...formValues[fieldName],
        value: fieldValue,
      },
    }

    switch (fieldName) {
      case PublishMachineField.countryId:
        // Reset the "State ID" dropdown when the "Country ID" has changed.
        newFormValues.stateId.value = Number(DROPDOWN_UNSELECTED_OPTION)
        break
      case PublishMachineField.categoryName:
        // Reset the "Manufacturer Name" and "Model Name" dropdowns when the "Category Name" has changed.
        newFormValues.manufacturerName.value = DROPDOWN_UNSELECTED_OPTION
        newFormValues.modelName.value = DROPDOWN_UNSELECTED_OPTION

        // Update the Publishing Title.
        newFormValues.publishingTitle.value = `${fieldValue} ${titleComplement}`
        break
      case PublishMachineField.manufacturerName:
        // Reset the "Model Name" dropdown when the "Manufacturer Name" has changed.
        newFormValues.modelName.value = DROPDOWN_UNSELECTED_OPTION

        // Update the Publishing Title.
        newFormValues.publishingTitle.value = `${newFormValues.categoryName.value} ${fieldValue} ${titleComplement}`
        break
      case PublishMachineField.modelName:
        // Update the Publishing Title.
        newFormValues.publishingTitle.value = `${newFormValues.categoryName.value} ${newFormValues.manufacturerName.value} ${fieldValue} ${titleComplement}`
        break
      default:
    }

    // Reset the value for the State ID every time the Country ID value changes.
    if (fieldName === PublishMachineField.countryId) {
      newFormValues.stateId.value = Number(DROPDOWN_UNSELECTED_OPTION)
    } else if (fieldName === PublishMachineField.categoryName) {
      newFormValues.manufacturerName.value = DROPDOWN_UNSELECTED_OPTION
      newFormValues.modelName.value = DROPDOWN_UNSELECTED_OPTION
    } else if (fieldName === PublishMachineField.manufacturerName) {
      newFormValues.modelName.value = DROPDOWN_UNSELECTED_OPTION
    }

    // Update the values for the form.
    setFormValues(newFormValues)

    if (fieldName === PublishMachineField.categoryName && typeof fieldValue === 'string') {
      // Set the params to be sent to the API for getting the list of "Manufacturers" related to the selected "Category".
      const manufacturerParams: GetManufactorersParams = {
        categoryName: fieldValue,
        basedOnMachines: MACHINERY_DROPDOWN_BASED_ON_MACHINES,
      }
  
      // Trigger the Redux action for fetching the list of Manufacturers.
      dispatchGetManufacturers(manufacturerParams)
    } else if (fieldName === PublishMachineField.manufacturerName && typeof fieldValue === 'string') {
      // Set the params to be sent to the API for getting the list of "Models" related to the selected "Category" and "Manufacturer".
      const modelParams: GetModelsParams = {
        categoryName: formValues.categoryName.value,
        manufacturerName: fieldValue,
        basedOnMachines: MACHINERY_DROPDOWN_BASED_ON_MACHINES,
      }
  
      // Trigger the Redux action for fetching the list of Models.
      dispatchGetModels(modelParams)
    }
  }

  const handleToggleSwitchChange = (fieldName: PublishMachineField) => (isChecked: boolean) => {
    // Update the value for the provided fieldname.
    setFormValues(prevState => ({
      ...prevState,
      [fieldName]: {
        ...prevState[fieldName],
        value: isChecked,
      },
    }))
  }

  const handlePublishMachineClick = () => {
    const username: string | undefined = isSignedIn ? undefined : formValues.username.value
    const password: string | undefined = isSignedIn ? undefined : formValues.password.value

    const isRegistrationValid: boolean = isSignedIn ? true : !!formValues.username.valid && !!formValues.password.valid && !!formValues.confirmPassword.valid
    const isBasicFieldsValid: boolean = !!formValues.contactPerson.valid && !!formValues.email.valid && !!formValues.mobile.valid
    const isMachineryValid: boolean = !!formValues.categoryName.valid && !!formValues.manufacturerName.valid && !!formValues.modelName.valid
    const isTitleValid: boolean = !!formValues.publishingTitle.valid
    const isLocationValid: boolean = !!formValues.countryId.valid && !!formValues.stateId.valid && !!formValues.city.valid
    const isSaleValid: boolean = [PublishingType.sale, PublishingType.both].includes(formValues.publishingType.value)
      ? !!formValues.price.valid
      : true
    const isFormValid: boolean = isRegistrationValid && isBasicFieldsValid && isMachineryValid && isTitleValid && isLocationValid && isSaleValid
    console.log('formValues', formValues)
    console.log('isRegistrationValid', isRegistrationValid)
    console.log('isBasicFieldsValid', isBasicFieldsValid)
    console.log('isMachineryValid', isMachineryValid)
    console.log('isTitleValid', isTitleValid)
    console.log('isSaleValid', isSaleValid)
    console.log('isFormValid', isFormValid)
    console.log('isFormValid', isFormValid)

    if (!isFormValid || !files.length) {
      // Display an error message.
      const alertProps: ShowAlertParams = {
        type: NotificationType.error,
        title: errorDictionary.somethingWentWrong,
        message: !isFormValid ? publishMachineDictionary.incompleteFormError : publishMachineDictionary.missingPhotos,
        buttonLabel: sharedDictionary.ok,
      }
  
      // Trigger the "showAlert" action for displaying the Alert modal.
      dispatchShowAlert(alertProps)
      return
    }

    const params: CreateMachineParams = {
      language,
      username,
      password,
      companyName: validateEmptyValue<string>(formValues.companyName.value),
      contactPerson: formValues.contactPerson.value,
      address: validateEmptyValue<string>(formValues.address.value),
      email: formValues.email.value,
      website: validateEmptyValue<string>(formValues.website.value),
      mobile: formValues.mobile.value,
      phone: validateEmptyValue<string>(formValues.phone.value),
      publishingType: formValues.publishingType.value,
      machineId: '',
      categoryName: formValues.categoryName.value,
      manufacturerName: formValues.manufacturerName.value,
      modelName: formValues.modelName.value,
      publishingTitle: formValues.publishingTitle.value,
      serialNumber: validateEmptyValue<string>(formValues.serialNumber.value),
      stockNumber: validateEmptyValue<string>(formValues.stockNumber.value),
      year: validateEmptyValue<number>(formValues.year.value),
      hours: validateEmptyValue<number>(Number(formValues.hours.value)),
      price: validateEmptyValue<number>(Number(formValues.price.value)),
      pricePerHour: validateEmptyValue<number>(Number(formValues.pricePerHour.value)),
      pricePerDay: validateEmptyValue<number>(Number(formValues.pricePerDay.value)),
      pricePerWeek: validateEmptyValue<number>(Number(formValues.pricePerWeek.value)),
      pricePerMonth: validateEmptyValue<number>(Number(formValues.pricePerMonth.value)),
      transportationRental: validateEmptyValue<number>(Number(formValues.transportationRental.value)),
      countryId: formValues.countryId.value,
      stateId: formValues.stateId.value,
      city: formValues.city.value,
      link: validateEmptyValue<string>(formValues.link.value),
      features: formValues.features.value,
      details: validateEmptyValue<string>(formValues.details.value),
      handledByCustomer: formValues.handledByCustomer.value,
      photos: [],
    }

    const imagesPath: string = `${process.env.REACT_APP_BASE_SERVER_URL}/photos`

    dispatchPublishMachine(params, files, imagesPath)
  }

  useEffect(() => {
    if (!countries) {
      dispatchGetCountries()
    }

    if (!categories) {
      const categoriesParams: GetCategoriesParams = {
        basedOnMachines: MACHINERY_DROPDOWN_BASED_ON_MACHINES,
      }

      dispatchGetCategories(categoriesParams)
    }
  }, [])

  useEffect(() => {
    if (!titleComplement) {
      const titleComplementsList: string[] = Object.entries(publishMachineDictionary)
        .filter(item => item[0].includes('publishingTitleComplement'))
        .map(item => item[1])
      const titleRandomIndex: number = Math.floor(Math.random() * titleComplementsList.length)
      const newTitleComplement: string = titleComplementsList[titleRandomIndex]

      setTitleComplement(newTitleComplement)
    }
  }, [titleComplement])

  useEffect(() => {
    if (countriesLoading) {
      return
    }

    if (countries?.length && !countriesOptions.length) {
      const countriesList: DropdownStructure[] = countries.map(country => ({
        label: country.country[language],
        value: country.countryId.toString(),
      }))

      setCountriesOptions(countriesList)
    }
  }, [countries, countriesLoading])

  useEffect(() => {
    const { countryId: {
      value: countryIdValue
    }  } = formValues

    if (countryIdValue > -1) {
      const params: GetStatesParams = {
        countryId: countryIdValue,
      }
  
      dispatchGetStates(params)
    }
  }, [formValues.countryId.value])

  useEffect(() => {
    if (statesLoading) {
      return
    }

    if (states?.length) {
      const statesList: DropdownStructure[] = states.map(state => ({
        label: state.state[language],
        value: state.stateId.toString(),
      }))

      setStatesOptions(statesList)
    }
  }, [states, statesLoading])

  useEffect(() => {
    if (!!categories && !categoriesOptions.length) {
      const topCategoriesList = categories.topCategories.filter(data => !data.category.es.includes('Covid')).map(data => ({
        label: data.category[language],
        value: data.category[language],
      }))

      const categoriesList = categories.categories.filter(data => !data.category.es.includes('Covid')).map(data => ({
        label: data.category[language],
        value: data.category[language],
      }))

      const newOptions: DropdownStructure[] = [
        {
          label: machineryDictionary.topCategories,
          value: '',
          isGroup: true,
          groupLabelRightSide: topCategoriesList.length.toLocaleString()
        },
        ...topCategoriesList,
        {
          label: machineryDictionary.otherCategories,
          value: '',
          isGroup: true,
          groupLabelRightSide: categoriesList.length.toLocaleString()
        },
        ...categoriesList,
      ]

      setCategoriesOptions(newOptions)
    }
  }, [categories])

  useEffect(() => {
    const isCategorySelected = !!formValues.categoryName.value && formValues.categoryName.value !== '-1'

    if (isCategorySelected && !manufacturers) {
      const manufacturersParams: GetManufactorersParams = {
        categoryName: formValues.categoryName.value,
        basedOnMachines: MACHINERY_DROPDOWN_BASED_ON_MACHINES,
      }

      dispatchGetManufacturers(manufacturersParams)
    } else if (isCategorySelected && manufacturers) {
      const topManufacturersList = manufacturers.topManufacturers.map(data => ({
        label: data.manufacturer,
        value: data.manufacturer,
      }))

      const manufacturersList = manufacturers.manufacturers.map(data => ({
        label: data.manufacturer,
        value: data.manufacturer,
      }))

      const newOptions: DropdownStructure[] = [
        {
          label: machineryDictionary.topManufacturers,
          value: '',
          isGroup: true,
          groupLabelRightSide: topManufacturersList.length.toLocaleString()
        },
        ...topManufacturersList,
        {
          label: machineryDictionary.otherManufacturers,
          value: '',
          isGroup: true,
          groupLabelRightSide: manufacturersList.length.toLocaleString()
        },
        ...manufacturersList,
      ]

      setManufacturersOptions(newOptions)
    }
  }, [formValues.categoryName.value, manufacturers])

  useEffect(() => {
    const isCategorySelected = !!formValues.categoryName.value && formValues.categoryName.value !== '-1'
    const isManufacturerSelected = !!formValues.manufacturerName.value && formValues.manufacturerName.value !== '-1'

    if (isCategorySelected && isManufacturerSelected && !models) {
      const modelsParams: GetModelsParams = {
        categoryName: formValues.categoryName.value,
        manufacturerName: formValues.manufacturerName.value,
        basedOnMachines: MACHINERY_DROPDOWN_BASED_ON_MACHINES,
      }

      dispatchGetModels(modelsParams)
    } else if (isCategorySelected && isManufacturerSelected && models) {
      const topModelsList = models.topModels.map(data => ({
        label: data.model,
        value: data.model,
      }))

      const modelsList = models.models.map(data => ({
        label: data.model,
        value: data.model,
      }))

      const newOptions: DropdownStructure[] = [
        {
          label: machineryDictionary.topModels,
          value: '',
          isGroup: true,
          groupLabelRightSide: topModelsList.length.toLocaleString()
        },
        ...topModelsList,
        {
          label: machineryDictionary.otherModels,
          value: '',
          isGroup: true,
          groupLabelRightSide: modelsList.length.toLocaleString()
        },
        ...modelsList,
      ]

      setModelsOptions(newOptions)
    }
  }, [formValues.categoryName.value, formValues.manufacturerName.value, models])

  useEffect(() => {
    if (isAlertOpen && alertMessage === errorDictionary.userExists) {
      setShouldResetUsername(true)
    } else if (shouldResetUsername && !isAlertOpen) {
      setShouldResetUsername(false)

      setFormValues(prevState => ({
        ...prevState,
        username: {
          ...prevState.username,
          value: '',
        },
      }))
    }
  }, [isAlertOpen, alertMessage, shouldResetUsername])

  return (
    <PageContainer ogDescription={publishMachineDictionary.pageDescription}>
      {!isSignedIn && (
        <>
          <EmailInput
            id={PublishMachineField.username}
            label={publishMachineDictionary.fieldUsername}
            placeholder={publishMachineDictionary.placeholderUsername}
            value={formValues.username.value}
            fullWidth
            required={formValues.username.required}
            isMissing={!formValues.username.valid && !!formValues.username.touched}
            onBlur={handleValidateUsername(formValues.username.value)}
            onChange={handleFieldChange(PublishMachineField.username)}
          />
          <DynamicRow
            alignItems={AlignItems.baseline}
            groupedByColumns={{ xs: '1', md: '2' }}
            gridRowGap={{ xs: '0' }}
            gridColumnGap={{ xs: '0', md: pxToRem(8) }}
          >
            <PasswordInput
              id={PublishMachineField.password}
              label={publishMachineDictionary.fieldPassword}
              placeholder={publishMachineDictionary.placeholderPassword}
              value={formValues.password.value}
              adornmentBgColor={adornmentBgColor}
              fullWidth
              required={formValues.password.required}
              isMissing={!formValues.password.valid && !!formValues.password.touched}
              generatePassword
              onBlur={handleValidateField(PublishMachineField.password)}
              onChange={handleFieldChange(PublishMachineField.password)}
            />
            <PasswordInput
              id={PublishMachineField.confirmPassword}
              label={publishMachineDictionary.fieldConfirmPassword}
              placeholder={publishMachineDictionary.placeholderConfirmPassword}
              value={formValues.confirmPassword.value}
              adornmentBgColor={adornmentBgColor}
              fullWidth
              required={formValues.confirmPassword.required}
              isMissing={!formValues.confirmPassword.valid && !!formValues.confirmPassword.touched}
              isMismatchError={isMismatchError}
              onBlur={handleComparePasswords(formValues.password.value, formValues.confirmPassword.value)}
              onChange={handleFieldChange(PublishMachineField.confirmPassword)}
            />
          </DynamicRow>
        </>
      )}
      <DynamicRow
        alignItems={AlignItems.baseline}
        groupedByColumns={{ xs: '1', md: '2' }}
        gridRowGap={{ xs: '0' }}
        gridColumnGap={{ xs: '0', md: pxToRem(8) }}
      >
        <StringInput
          id={PublishMachineField.companyName}
          label={publishMachineDictionary.fieldCompanyName}
          placeholder={publishMachineDictionary.placeholderCompanyName}
          value={formValues.companyName.value}
          fullWidth
          required={formValues.companyName.required}
          isMissing={!formValues.companyName.valid && !!formValues.companyName.touched}
          onBlur={handleValidateField(PublishMachineField.companyName)}
          onChange={handleFieldChange(PublishMachineField.companyName)}
        />
        <StringInput
          id={PublishMachineField.contactPerson}
          label={publishMachineDictionary.fieldContactPerson}
          placeholder={publishMachineDictionary.placeholderContactPerson}
          value={formValues.contactPerson.value}
          fullWidth
          required={formValues.contactPerson.required}
          isMissing={!formValues.contactPerson.valid && !!formValues.contactPerson.touched}
          onBlur={handleValidateField(PublishMachineField.contactPerson)}
          onChange={handleFieldChange(PublishMachineField.contactPerson)}
        />
      </DynamicRow>
      <DynamicRow
        alignItems={AlignItems.baseline}
        groupedByColumns={{ xs: '1', md: '2' }}
        gridRowGap={{ xs: '0' }}
        gridColumnGap={{ xs: '0', md: pxToRem(8) }}
      >
        <EmailInput
          id={PublishMachineField.email}
          label={publishMachineDictionary.fieldEmail}
          placeholder={publishMachineDictionary.placeholderEmail}
          value={formValues.email.value}
          fullWidth
          required={formValues.email.required}
          isMissing={!formValues.email.valid && !!formValues.email.touched}
          onBlur={handleValidateField(PublishMachineField.email)}
          onChange={handleFieldChange(PublishMachineField.email)}
        />
        <UrlInput
          id={PublishMachineField.website}
          label={publishMachineDictionary.fieldWebsite}
          placeholder={publishMachineDictionary.placeholderWebsite}
          value={formValues.website.value}
          fullWidth
          required={formValues.website.required}
          isMissing={!formValues.website.valid && !!formValues.website.touched}
          onBlur={handleValidateField(PublishMachineField.website)}
          onChange={handleFieldChange(PublishMachineField.website)}
        />
      </DynamicRow>
      <DynamicRow
        alignItems={AlignItems.baseline}
        groupedByColumns={{ xs: '1', md: '2' }}
        gridRowGap={{ xs: '0' }}
        gridColumnGap={{ xs: '0', md: pxToRem(8) }}
      >
        <PhoneNumberInput
          label={publishMachineDictionary.fieldMobile}
          value={formValues.mobile.value}
          fullWidth
          required={formValues.mobile.required}
          isMissing={!formValues.mobile.valid && !!formValues.mobile.touched}
          onBlur={handleValidateField(PublishMachineField.mobile)}
          onChange={handleFieldChange(PublishMachineField.mobile)}
        />
        <PhoneNumberInput
          label={publishMachineDictionary.fieldPhone}
          value={formValues.phone.value}
          fullWidth
          required={formValues.phone.required}
          isMissing={!formValues.phone.valid && !!formValues.phone.touched}
          onBlur={handleValidateField(PublishMachineField.phone)}
          onChange={handleFieldChange(PublishMachineField.phone)}
        />
      </DynamicRow>
      <DynamicRow
        alignItems={AlignItems.baseline}
        groupedByColumns={{ xs: '1', md: '2' }}
        gridRowGap={{ xs: '0' }}
        gridColumnGap={{ xs: '0', md: pxToRem(8) }}
      >
        <DropdownSingle
          id={PublishMachineField.publishingType}
          label={publishMachineDictionary.fieldPublishingType}
          options={publishingTypeOption}
          value={formValues.publishingType.value.toString()}
          fullWidth
          required={formValues.publishingType.required}
          isMissing={!formValues.publishingType.valid && !!formValues.publishingType.touched}
          onBlur={handleValidateField(PublishMachineField.publishingType)}
          onChange={handleDropdownChange(PublishMachineField.publishingType)}
        />
        <Row groupedByTemplate="1fr auto">
          <LabelWithoutMargin variant="body1" ellipsis>
            {publishMachineDictionary.handledByCustomer}
          </LabelWithoutMargin>
          <ToggleSwitch
            checked={formValues.handledByCustomer.value}
            onChange={handleToggleSwitchChange(PublishMachineField.handledByCustomer)}
          />
        </Row>
      </DynamicRow>
      <DynamicRow
        alignItems={AlignItems.baseline}
        groupedByColumns={{ xs: '1', md: '3' }}
        gridRowGap={{ xs: '0' }}
        gridColumnGap={{ xs: '0', md: pxToRem(8) }}
      >
        <DropdownSingle
          id={PublishMachineField.categoryName}
          label={publishMachineDictionary.fieldCategory}
          nonValueLabel={publishMachineDictionary.placeholderCategory}
          options={categoriesOptions}
          value={formValues.categoryName.value}
          disabled={isCategoryDisabled}
          fullWidth
          required={formValues.categoryName.required}
          isMissing={!formValues.categoryName.valid && !!formValues.categoryName.touched}
          onBlur={handleValidateField(PublishMachineField.categoryName)}
          onChange={handleDropdownChange(PublishMachineField.categoryName)}
        />
        <DropdownSingle
          id={PublishMachineField.manufacturerName}
          label={publishMachineDictionary.fieldManufacturer}
          nonValueLabel={publishMachineDictionary.placeholderManufacturer}
          options={manufacturersOptions}
          value={formValues.manufacturerName.value}
          disabled={isManufacturerDisabled}
          fullWidth
          required={formValues.manufacturerName.required}
          isMissing={!formValues.manufacturerName.valid && !!formValues.manufacturerName.touched}
          onBlur={handleValidateField(PublishMachineField.manufacturerName)}
          onChange={handleDropdownChange(PublishMachineField.manufacturerName)}
        />
        <DropdownSingle
          id={PublishMachineField.modelName}
          label={publishMachineDictionary.fieldModel}
          nonValueLabel={publishMachineDictionary.placeholderModel}
          options={modelsOptions}
          value={formValues.modelName.value}
          disabled={isModelDisabled}
          fullWidth
          required={formValues.modelName.required}
          isMissing={!formValues.modelName.valid && !!formValues.modelName.touched}
          onBlur={handleValidateField(PublishMachineField.modelName)}
          onChange={handleDropdownChange(PublishMachineField.modelName)}
        />
      </DynamicRow>
      <StringInput
        id={PublishMachineField.publishingTitle}
        label={publishMachineDictionary.fieldPublishingTitle}
        placeholder={publishMachineDictionary.placeholderPublishingTitle}
        value={formValues.publishingTitle.value}
        fullWidth
        required={formValues.publishingTitle.required}
        isMissing={!formValues.publishingTitle.valid && !!formValues.publishingTitle.touched}
        onBlur={handleValidateField(PublishMachineField.publishingTitle)}
        onChange={handleFieldChange(PublishMachineField.publishingTitle)}
      />
      <DynamicRow
        alignItems={AlignItems.baseline}
        groupedByColumns={{ xs: '1', md: isSale ? '3' : '2' }}
        gridRowGap={{ xs: '0' }}
        gridColumnGap={{ xs: '0', md: pxToRem(8) }}
      >
        <StringInput
          id={PublishMachineField.serialNumber}
          label={publishMachineDictionary.fieldSerialNumber}
          placeholder={publishMachineDictionary.placeholderSerialNumber}
          value={formValues.serialNumber.value}
          fullWidth
          required={formValues.serialNumber.required}
          isMissing={!formValues.serialNumber.valid && !!formValues.serialNumber.touched}
          onBlur={handleValidateField(PublishMachineField.serialNumber)}
          onChange={handleFieldChange(PublishMachineField.serialNumber)}
        />
        <StringInput
          id={PublishMachineField.stockNumber}
          label={publishMachineDictionary.fieldStockNumber}
          placeholder={publishMachineDictionary.placeholderStockNumber}
          value={formValues.stockNumber.value}
          fullWidth
          required={formValues.stockNumber.required}
          isMissing={!formValues.stockNumber.valid && !!formValues.stockNumber.touched}
          onBlur={handleValidateField(PublishMachineField.stockNumber)}
          onChange={handleFieldChange(PublishMachineField.stockNumber)}
        />
        {isSale && (
          <MoneyInput
            id={PublishMachineField.price}
            label={publishMachineDictionary.fieldPrice}
            placeholder={publishMachineDictionary.placeholderPrice}
            value={formValues.price.value}
            fullWidth
            required={formValues.price.required}
            isMissing={!formValues.price.valid && !!formValues.price.touched}
            onBlur={handleValidateField(PublishMachineField.price)}
            onChange={handleFieldChange(PublishMachineField.price)}
          />
        )}
      </DynamicRow>
      {isRent && (
        <DynamicRow
          alignItems={AlignItems.baseline}
          groupedByColumns={{ xs: '1', md: '4' }}
          gridRowGap={{ xs: '0' }}
          gridColumnGap={{ xs: '0', md: pxToRem(8) }}
        >
          <MoneyInput
            id={PublishMachineField.pricePerHour}
            label={publishMachineDictionary.fieldPricePerHour}
            placeholder={publishMachineDictionary.placeholderPricePerHour}
            value={formValues.pricePerHour.value}
            fullWidth
            required={formValues.pricePerHour.required}
            isMissing={!formValues.pricePerHour.valid && !!formValues.pricePerHour.touched}
            onBlur={handleValidateField(PublishMachineField.pricePerHour)}
            onChange={handleFieldChange(PublishMachineField.pricePerHour)}
          />
          <MoneyInput
            id={PublishMachineField.pricePerDay}
            label={publishMachineDictionary.fieldPricePerDay}
            placeholder={publishMachineDictionary.placeholderPricePerDay}
            value={formValues.pricePerDay.value}
            fullWidth
            required={formValues.pricePerDay.required}
            isMissing={!formValues.pricePerDay.valid && !!formValues.pricePerDay.touched}
            onBlur={handleValidateField(PublishMachineField.pricePerDay)}
            onChange={handleFieldChange(PublishMachineField.pricePerDay)}
          />
          <MoneyInput
            id={PublishMachineField.pricePerWeek}
            label={publishMachineDictionary.fieldPricePerWeek}
            placeholder={publishMachineDictionary.placeholderPricePerWeek}
            value={formValues.pricePerWeek.value}
            fullWidth
            required={formValues.pricePerWeek.required}
            isMissing={!formValues.pricePerWeek.valid && !!formValues.pricePerWeek.touched}
            onBlur={handleValidateField(PublishMachineField.pricePerWeek)}
            onChange={handleFieldChange(PublishMachineField.pricePerWeek)}
          />
          <MoneyInput
            id={PublishMachineField.pricePerMonth}
            label={publishMachineDictionary.fieldPricePerMonth}
            placeholder={publishMachineDictionary.placeholderPricePerMonth}
            value={formValues.pricePerMonth.value}
            fullWidth
            required={formValues.pricePerMonth.required}
            isMissing={!formValues.pricePerMonth.valid && !!formValues.pricePerMonth.touched}
            onBlur={handleValidateField(PublishMachineField.pricePerMonth)}
            onChange={handleFieldChange(PublishMachineField.pricePerMonth)}
          />
        </DynamicRow>
      )}
      {isRent && (
        <MoneyInput
          id={PublishMachineField.transportationRental}
          label={publishMachineDictionary.fieldTransportationRental}
          placeholder={publishMachineDictionary.placeholderTransportationRental}
          value={formValues.transportationRental.value}
          fullWidth
          required={formValues.transportationRental.required}
          isMissing={!formValues.transportationRental.valid && !!formValues.transportationRental.touched}
          onBlur={handleValidateField(PublishMachineField.transportationRental)}
          onChange={handleFieldChange(PublishMachineField.transportationRental)}
        />
      )}
      <DynamicRow
        alignItems={AlignItems.baseline}
        groupedByColumns={{ xs: '1', md: '2' }}
        gridRowGap={{ xs: '0' }}
        gridColumnGap={{ xs: '0', md: pxToRem(8) }}
      >
        <DropdownSingle
          id={PublishMachineField.year}
          label={publishMachineDictionary.fieldYear}
          nonValueLabel={publishMachineDictionary.placeholderYear}
          options={yearOptions}
          value={formValues.year.value.toString()}
          fullWidth
          required={formValues.year.required}
          isMissing={!formValues.year.valid && !!formValues.year.touched}
          onBlur={handleValidateField(PublishMachineField.year)}
          onChange={handleDropdownChange(PublishMachineField.year, true)}
        />
        <NumericInput
          id={PublishMachineField.hours}
          label={publishMachineDictionary.fieldHours}
          placeholder={publishMachineDictionary.placeholderHours}
          value={formValues.hours.value}
          fullWidth
          required={formValues.hours.required}
          isMissing={!formValues.hours.valid && !!formValues.hours.touched}
          onBlur={handleValidateField(PublishMachineField.hours)}
          onChange={handleFieldChange(PublishMachineField.hours, true)}
        />
      </DynamicRow>
      <DynamicRow
        alignItems={AlignItems.baseline}
        groupedByColumns={{ xs: '1', md: '2' }}
        gridRowGap={{ xs: '0' }}
        gridColumnGap={{ xs: '0', md: pxToRem(8) }}
      >
        <DropdownSingle
          id={PublishMachineField.countryId}
          label={publishMachineDictionary.fieldCountry}
          nonValueLabel={publishMachineDictionary.placeholderCountry}
          options={countriesOptions}
          value={formValues.countryId.value.toString()}
          fullWidth
          required={formValues.countryId.required}
          isMissing={!formValues.countryId.valid && !!formValues.countryId.touched}
          onBlur={handleValidateField(PublishMachineField.countryId)}
          onChange={handleDropdownChange(PublishMachineField.countryId, true)}
        />
        <DropdownSingle
          id={PublishMachineField.stateId}
          label={publishMachineDictionary.fieldState}
          nonValueLabel={publishMachineDictionary.placeholderState}
          options={statesOptions}
          value={formValues.stateId.value.toString()}
          fullWidth
          required={formValues.stateId.required}
          isMissing={!formValues.stateId.valid && !!formValues.stateId.touched}
          disabled={formValues.countryId.value === -1}
          onBlur={handleValidateField(PublishMachineField.stateId)}
          onChange={handleDropdownChange(PublishMachineField.stateId, true)}
        />
      </DynamicRow>
      <DynamicRow
        alignItems={AlignItems.baseline}
        groupedByColumns={{ xs: '1', md: '2' }}
        gridRowGap={{ xs: '0' }}
        gridColumnGap={{ xs: '0', md: pxToRem(8) }}
      >
        <StringInput
          id={PublishMachineField.city}
          label={publishMachineDictionary.fieldCity}
          placeholder={publishMachineDictionary.placeholderCity}
          value={formValues.city.value}
          fullWidth
          required={formValues.city.required}
          isMissing={!formValues.city.valid && !!formValues.city.touched}
          onBlur={handleValidateField(PublishMachineField.city)}
          onChange={handleFieldChange(PublishMachineField.city)}
        />
        <StringInput
          id={PublishMachineField.address}
          label={publishMachineDictionary.fieldAddress}
          placeholder={publishMachineDictionary.placeholderAddress}
          value={formValues.address.value}
          fullWidth
          required={formValues.address.required}
          isMissing={!formValues.address.valid && !!formValues.address.touched}
          onBlur={handleValidateField(PublishMachineField.address)}
          onChange={handleFieldChange(PublishMachineField.address)}
        />
      </DynamicRow>
      <MultiTextBox
        id={PublishMachineField.features}
        label={publishMachineDictionary.fieldFeatures}
        placeholder={publishMachineDictionary.placeholderFeatures}
        value={formValues.features.value}
        fullWidth
        required={formValues.features.required}
        isMissing={!formValues.features.valid && !!formValues.features.touched}
        onBlur={handleValidateField(PublishMachineField.features)}
        onChange={handleFieldChange(PublishMachineField.features)}
      />
      <MultiTextBox
        id={PublishMachineField.details}
        label={publishMachineDictionary.fieldDetails}
        placeholder={publishMachineDictionary.placeholderDetails}
        value={formValues.details.value}
        fullWidth
        required={formValues.details.required}
        isMissing={!formValues.details.valid && !!formValues.details.touched}
        onBlur={handleValidateField(PublishMachineField.details)}
        onChange={handleFieldChange(PublishMachineField.details)}
      />
      <Row marginTop={pxToRem(8)} justifyContent={JustifyContent.center}>
        <DropzoneArea
          acceptedFiles={['image/jpeg, image/png']}
          filesLimit={MAX_IMAGES_ALLOWED}
          dropzoneText={publishMachineDictionary.dragAndDropPhotos}
          getDropRejectMessage={(rejectedFile) => sharedDictionary.dragAndDrodRejectImage.replace('{FILE_NAME}', rejectedFile.name)}
          getFileAddedMessage={(fileName) => sharedDictionary.dragAndDrodAddFile.replace('{FILE_NAME}', fileName)}
          getFileRemovedMessage={(fileName) => sharedDictionary.dragAndDrodRemoveFile.replace('{FILE_NAME}', fileName)}
          getFileLimitExceedMessage={(filesLimit) => sharedDictionary.dragAndDrodImageLimitExceedMessage.replace('{MAX_IMAGES_ALLOWED}', filesLimit.toString())}
          clearOnUnmount
          onChange={handleUpdateFiles}
        />
      </Row>
      <Row marginTop={pxToRem(16)} paddingBottom={pxToRem(16)} justifyContent={JustifyContent.center}>
        <PrimaryButton label={publishMachineDictionary.submitButton} onClick={handlePublishMachineClick} />
      </Row>
    </PageContainer>
  )
}

const mapStateToProps = ({ alertStore, countriesStore, languageStore, machineryStore, sessionStore, statesStore }: ReduxStore) => {
  const { isOpen: isAlertOpen, message: alertMessage } = alertStore
  const { countries, countriesLoading } = countriesStore
  const { dictionary, language } = languageStore
  const {
    categories,
    categoriesLoading,
    manufacturers,
    manufacturersLoading,
    models,
    modelsLoading,
  } = machineryStore
  const { isSignedIn } = sessionStore
  const { states, statesLoading } = statesStore

  return {
    isAlertOpen,
    alertMessage,
    dictionary,
    language,
    categories,
    categoriesLoading,
    manufacturers,
    manufacturersLoading,
    models,
    modelsLoading,
    isSignedIn,
    countries,
    countriesLoading,
    states,
    statesLoading,
  }
}

const mapDispatchToProps = (dispatch) => ({
  dispatchCheckUsernameAvailability: (username: string) => dispatch(checkUsernameAvailability(username)),
  dispatchGetCategories: (params: GetCategoriesParams) => dispatch(getCategories(params)),
  dispatchGetCountries: () => dispatch(getCountries()),
  dispatchGetManufacturers: (params: GetManufactorersParams) => dispatch(getManufacturers(params)),
  dispatchGetModels: (params: GetModelsParams) => dispatch(getModels(params)),
  dispatchGetStates: (params: GetStatesParams) => dispatch(getStates(params)),
  dispatchPublishMachine: (params: CreateMachineParams, files: File[], imagesPath: string) => dispatch(publishMachine(params, files, imagesPath)),
  dispatchShowAlert: (alertProps: ShowAlertParams) => dispatch(showAlert(alertProps)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PublishMachine)
