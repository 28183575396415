import { isNull } from 'lodash'
import camelCase from 'lodash/camelCase'
import snakeCase from 'lodash/snakeCase'
import { StoredSession } from 'redux/utils/session.types'
import { STORAGE_KEY_USER_PROFILE } from 'utils/constants'
import { encodeContentUtf8, getFromLocalStorage } from 'utils/libs'
import { STORED_SESSION_INITIAL_STATE } from './constants'
import { ApiParams } from './types'

export const camelCaseToSnake = (key) => snakeCase(key)
export const snakeCaseToCamel = (key) => camelCase(key)

export const formatApiParams = (obj): { params: string } => {
  const result = {}

  Object.keys(obj).forEach(key => {
    result[camelCaseToSnake(key)] = obj[key]
  })

  return {
    params: encodeContentUtf8(JSON.stringify(result)),
  }
}

export const formatResponseParams = <T>(obj): T => {
  const result = {} as T

  Object.keys(obj).forEach(key => {
    result[snakeCaseToCamel(key)] = obj[key]
  })

  return result
}

/**
 * Gets the data stored into the Local Storage for the User's session.
 */
export const getStoredSession = (): StoredSession =>
  getFromLocalStorage<StoredSession>(STORAGE_KEY_USER_PROFILE) || STORED_SESSION_INITIAL_STATE

/**
 * Gets the User's token from the Stored Session.
 */
export const getToken = (): string => {
  const storedSession = getStoredSession()
  const token: string = !isNull(storedSession.token)
    ? storedSession.token
    : ''

  return token
}

/**
 * Defines the API Headers to be used when an API request is made.
 * @param isSignedIn Specifies whether the user is signed-in or not. Also, it can specifies if the user should be signed-in.
 * @param token Specifies the User's Token that should have when signed-in.
 * @param dataParams Specifies any possible params for the "data" prop. (OPTIONAL).
 */
export const getApiHeaders = (isSignedIn: boolean, token: string | null, dataParams?: ApiParams) => {
  const authorization: string = (isSignedIn && token) ? token : ''

  return {
    headers: {
      authorization,
      'Content-Type': 'application/json',
      accept: 'application/json'
    },
    data: dataParams,
  }
}