import {
  Phone as ContactIcon,
  PictureAsPdf as PDFIcon,
  Share as ShareIcon,
  WhatsApp as WhatsAppIcon,
} from '@mui/icons-material'
import { CircularProgress } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { ActionButton, ActionLink, Row, SkeletonImage, ThumbnailFooterButtonContainer } from 'components/styledComponents'
import { AlignItems, JustifyContent } from 'components/utils/enums'
import { usePdfDetails, useSocialMedia } from 'hooks'
import MachineDetailsPDF from 'pages/Machinery/MachineDetails/MachineDetailsPDF'
import SocialMediaShareModal from 'pages/Machinery/SocialMediaShareModal'
import { MachineDetailsPDFProps, MachineSocialMediaData, MachineThumbnail, QuotationDetails } from 'pages/Machinery/utils/types'
import React, { FC, useState } from 'react'
import { QuotationValues } from 'redux/utils/quotation.types'
import { pxToRem } from 'theme/typography'
import { CELL_NUMBER } from 'utils/constants'

const useStyles = makeStyles((theme: Theme) => ({
  actionButtonLink: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: `${AlignItems.center}`,
    justifyContent: `${JustifyContent.center}`,
    color: theme.palette.common.white,
  },
}))

type Props = {
  showSkeleton: boolean
  thumbnailMachine?: MachineThumbnail
  quotationValues?: QuotationValues | null
  quotationDetails?: QuotationDetails[]
}

const ActionButtons: FC<Props> = ({
  showSkeleton,
  thumbnailMachine,
  quotationValues,
  quotationDetails,
}) => {
  const classes = useStyles()
  const [isSocialMediaModalOpen, setIsSocialMediaModalOpen] = useState<boolean>(false)
  const [machineInfo, setMachineInfo] = useState<string>('')
  const [machineURL, setMachineURL] = useState<string>('')
  const [whatsAppURL, setWhatsAppURL] = useState<string>('')
  const pdfProps: MachineDetailsPDFProps | null = usePdfDetails({ thumbnailMachine, quotationValues, quotationDetails })
  const socialMedia: MachineSocialMediaData = useSocialMedia(thumbnailMachine)
  const footerButtonProps = !thumbnailMachine
    ? { className: 'machine-details' }
    : {}

  /**
   * Displays a Modal for sharing the Machine through the Social Media: WhatsApp, Facebook and Twitter.
   * @param contactInfoMessage The message to be shared though the Social Media.
   * @param machineDetailsURL The URL of the Machine to be shared.
   * @param whatsAppBaseURL The base URL for WhatsApp based on the device: Mobile/Desktop.
   */
   const handleSocialMediaModalOpen = (): void => {
    setMachineInfo(socialMedia.machineInfo)
    setMachineURL(socialMedia.machineDetailsURL)
    setWhatsAppURL(socialMedia.whatsAppBaseURL)
    setIsSocialMediaModalOpen(true)
  }

  /**
   * Closes the Modal for sharing the Machine through the Social Media.
   */
  const handleSocialMediaModalClose = () => {
    setIsSocialMediaModalOpen(false)
  }

  return (
    <>
      <Row groupedByColumns={4} gridGap={2} paddingBottom={pxToRem(16)}>
        <ThumbnailFooterButtonContainer {...footerButtonProps}>
          {showSkeleton || !pdfProps
            ? <SkeletonImage borderRadius={0} />
            : (
                <PDFDownloadLink
                  className={classes.actionButtonLink}
                  document={<MachineDetailsPDF {...pdfProps} />}
                  fileName={pdfProps.fileName}
                >
                  {({ loading }) => loading ? <CircularProgress size={16} className="action-button" /> : <PDFIcon />}
                </PDFDownloadLink>
              )
          }
        </ThumbnailFooterButtonContainer>
        <ThumbnailFooterButtonContainer {...footerButtonProps}>
          {showSkeleton
            ? <SkeletonImage borderRadius={0} />
            : (
                <ActionLink href={`tel:${CELL_NUMBER}`} className="action-button">
                  <ContactIcon />
                </ActionLink>
              )
          }
        </ThumbnailFooterButtonContainer>
        <ThumbnailFooterButtonContainer {...footerButtonProps}>
          {showSkeleton
            ? <SkeletonImage borderRadius={0} />
            : (
                <ActionLink href={socialMedia.contactInfoMessage} className="action-button">
                  <WhatsAppIcon />
                </ActionLink>
              )
          }
        </ThumbnailFooterButtonContainer>
        <ThumbnailFooterButtonContainer {...footerButtonProps}>
          {showSkeleton
            ? <SkeletonImage borderRadius={0} />
            : (
                <ActionButton className="action-button" onClick={handleSocialMediaModalOpen}>
                  <ShareIcon />
                </ActionButton>
              )
          }
        </ThumbnailFooterButtonContainer>
      </Row>
      <SocialMediaShareModal
        isOpen={isSocialMediaModalOpen}
        machineInfo={machineInfo}
        machineURL={machineURL}
        whatsAppURL={whatsAppURL}
        closable
        onClose={handleSocialMediaModalClose}
      />
    </>
  )
}

export default ActionButtons