import { InputAdornment, TextField } from '@mui/material'
import { StyledFormControl } from 'components/FormFields/styledComponents'
import { LabelWithoutMargin } from 'components/styledComponents'
import { AdornmentPosition, InputSize, InputType, InputVariant } from 'components/utils/enums'
import { InputProps } from 'components/utils/types'
import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { LanguageDictionary } from 'redux/utils/language.types'
import { ReduxStore } from 'redux/utils/types'
import { pxToRem } from 'theme/typography'

type Props = {
  dictionary: LanguageDictionary
  id?: string
  label: string
  value?: string
  placeholder?: string
  size?: InputSize
  fullWidth?: boolean
  required?: boolean
  isMissing?: boolean
  disabled?: boolean
  variant?: InputVariant
  marginTop?: string | number
  marginBottom?: string | number
  onBlur?: (hasErrors?: boolean) => void
  onChange: (newValue: string) => void
  onKeyDown?: (event: any) => void
  onKeyUpCapture?: (event: any) => void
}

const UnconnectedMoneyInput: FC<Props> = ({
  dictionary: { error: errorDictionary },
  id,
  label,
  value,
  placeholder,
  size = InputSize.small,
  fullWidth,
  required,
  isMissing,
  disabled,
  variant = InputVariant.outlined,
  marginTop = pxToRem(8),
  marginBottom = pxToRem(16),
  onBlur,
  onChange,
  onKeyDown,
  onKeyUpCapture,
}) => {
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [isTouched, setIsTouched] = useState<boolean>(false)
  const inputProps: InputProps = {
    type: 'number',
  }

  const className: string | undefined = disabled ? 'Mui-focused' : ''

  inputProps.startAdornment = (
    <InputAdornment className="money-input" position={AdornmentPosition.start}>
      <LabelWithoutMargin>$</LabelWithoutMargin>
    </InputAdornment>
  )

  inputProps.endAdornment = (
    <InputAdornment className="money-input" position={AdornmentPosition.end}>
      <LabelWithoutMargin>USD</LabelWithoutMargin>
    </InputAdornment>
  )

  /**
   * Handles the event when the input value has changed.
   * @param event The input event handler which contains the typed value.
   */
  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { value: newValue } = event.target
    onChange(newValue)
  }

  /**
   * Handles the event when the input loses focus.
   */
  const handleBlur = (): void => {
    // Set the input has been touched, which means the input has been focused at least once.
    setIsTouched(true)

    // Check wheter the input value has an error due it's required.
    const isRequiredError = required && !value

    // Keep the normal Blur behavior when there is no error.
    if (!isRequiredError) {
      onBlur?.()
      return
    }

    // Define the error message
    setErrorMessage(errorDictionary.requiredValue)
    onBlur?.(true)
  }

  useEffect(() => {
    // Set an error message when the field is required but it is missing.
    const isRequiredError = required && !value && (isTouched || isMissing)
    const newErrorMessage = isRequiredError ? errorDictionary.requiredValue : ''
    setErrorMessage(newErrorMessage)

    // Set the field as touched when it is required and missing and the "isTouched" flag is off.
    if (required && isMissing && !isTouched) {
      setIsTouched(true)
    }
  }, [value, isMissing])

  return (
    <StyledFormControl
      fullWidth={fullWidth}
      marginTop={marginTop}
      marginBottom={marginBottom}
      variant={variant}
      size={size}
    >
      <TextField
        className={`money-input ${className}`}
        id={id}
        type={InputType.text}
        placeholder={placeholder}
        label={label}
        value={value}
        size={size}
        variant={variant}
        InputProps={inputProps}
        error={!!errorMessage}
        required={required}
        disabled={disabled}
        focused
        helperText={errorMessage}
        onChange={handleChange}
        onBlur={handleBlur}
        onKeyDown={onKeyDown}
        onKeyUpCapture={onKeyUpCapture}
      />
    </StyledFormControl>
  )
}

const mapStateToProps = ({ languageStore }: ReduxStore) => {
  const { dictionary } = languageStore

  return {
    dictionary,
  }
}

export const MoneyInput = connect(
  mapStateToProps,
)(UnconnectedMoneyInput)