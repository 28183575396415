import { Menu as MenuIcon } from '@mui/icons-material'
import { Drawer, IconButton } from '@mui/material'
import GuestsMenuOptions from 'components/Navigation/Header/GuestsMenuOptions'
import Logo from 'components/Navigation/Logo'
import {
  HeaderContainerMobile,
  GreetingContainerMobile,
  GreetingMobile,
  MainListContainerMobile,
  MenuContainerMobile,
  MainContainer,
  StyledAvatar,
} from 'components/Navigation/styledComponents'
import UsersMenuOptions from 'components/Navigation/UsersMenu/UsersMenuOptions'
import { LabelWithoutMargin } from 'components/styledComponents'
import { DeviceVisibility } from 'components/utils/enums'
import { useGetGreeting } from 'hooks'
import React, { FC, KeyboardEvent, MouseEvent, useState } from 'react'
import { connect } from 'react-redux'
import { Language } from 'redux/utils/enums'
import { LanguageDictionary } from 'redux/utils/language.types'
import { ReduxStore } from 'redux/utils/types'
import { stringToColor } from 'utils/libs'
import { UserName } from 'utils/types'

type Props = {
  dictionary: LanguageDictionary
  language: Language
  isSignedIn: boolean
  userAvatar?: string
  userFirstName: string | null
  userLastName: string | null
  userFullName: string
  timezone: string
}

const HeaderMobile: FC<Props> = ({
  dictionary: { navbar: navbarDictionary },
  language,
  isSignedIn,
  userAvatar,
  userFirstName,
  userLastName,
  userFullName,
  timezone,
}) => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false)

  const userName: UserName | undefined = isSignedIn
    ? {
        firstName: userFirstName,
        lastName: userLastName,
        fullName: userFullName,
      }
    : undefined

  const { greetingLabel, greetingDate, greetingUserName, avatarInitial } = useGetGreeting(language, timezone, navbarDictionary, userName)
  
  const handleMenuToggle = (isMenuOpen: boolean) => (event: KeyboardEvent | MouseEvent) => {
    const isEventTypeKeydown = event.type === 'keydown'
    const isEventKeyTab = (event as KeyboardEvent).key === 'Tab'
    const isEventKeyShift = (event as KeyboardEvent).key === 'Shift'

    if (isEventTypeKeydown && (isEventKeyTab || isEventKeyShift)) {
      return
    }

    setMenuOpen(isMenuOpen)
  }

  return (
    <HeaderContainerMobile>
      <MainContainer className="mobile">
        <IconButton
          size="large"
          aria-controls="appbar-menu"
          aria-haspopup="true"
          onClick={handleMenuToggle(true)}
          color="inherit"
        >
          <MenuIcon />
        </IconButton>
        <Logo />
        <LabelWithoutMargin>IMCMEXICO ®</LabelWithoutMargin>
        <Drawer
          className={isSignedIn ? 'users-menu mobile' : ''}
          anchor="left"
          open={menuOpen}
          onClose={handleMenuToggle(false)}
        >
          <MenuContainerMobile
            role="presentation"
            onClick={handleMenuToggle(false)}
            onKeyDown={handleMenuToggle(false)}
          >
            <GreetingContainerMobile>
              {isSignedIn && (
                <StyledAvatar src={userAvatar} backgroundColor={stringToColor(userFullName)}>
                  {avatarInitial}
                </StyledAvatar>
              )}
              <GreetingMobile>
                <LabelWithoutMargin variant="subtitle1">
                  {greetingLabel}
                </LabelWithoutMargin>
                <LabelWithoutMargin variant="body2">
                  {isSignedIn ? greetingUserName : greetingDate}
                </LabelWithoutMargin>
              </GreetingMobile>
            </GreetingContainerMobile>
            <MainListContainerMobile>
              {isSignedIn
                ? <UsersMenuOptions device={DeviceVisibility.mobile} />
                : <GuestsMenuOptions device={DeviceVisibility.mobile} />
              }
            </MainListContainerMobile>
          </MenuContainerMobile>
        </Drawer>
      </MainContainer>
    </HeaderContainerMobile>
  )
}

const mapStateToProps = ({ languageStore, sessionStore, timezoneStore }: ReduxStore) => {
  const { language, dictionary } = languageStore
  const { isSignedIn, sessionData } = sessionStore
  const { timezone } = timezoneStore

  const {
    userAvatar,
    userFirstName,
    userLastName,
    userFullName,
  } = sessionData || {}

  return {
    dictionary,
    language,
    isSignedIn,
    userAvatar,
    userFirstName,
    userLastName,
    userFullName,
    timezone,
  }
}

export default connect(
  mapStateToProps,
)(HeaderMobile)