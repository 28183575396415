export enum SignupFormField {
  userType = 'userType',
  username = 'username',
  password = 'password',
  confirmPassword = 'confirmPassword',
  name = 'name',
  countryId = 'countryId',
  stateId = 'stateId',
  city = 'city',
  mobile = 'mobile',
  phoneMain = 'phone',
  secondaryEmail = 'secondaryEmail',
}

export enum UserType {
  superAdmin = 'superAdmin',
  admin = 'admin',
  supervisor = 'supervisor',
  operator = 'operator',
  customer = 'customer',
  broker = 'broker',
  supplier = 'supplier',
  cmic = 'cmic',
  freePublication = 'freePublication',
  notValidated = 'notValidated',
}

export enum UserFormField {
  userType = 'userType',
  username = 'username',
  password = 'password',
  confirmPassword = 'confirmPassword',
  companyName = 'companyName',
  contactPerson = 'contactPerson',
  countryId = 'countryId',
  stateId = 'stateId',
  city = 'city',
  address = 'address',
  managingDirector = 'managingDirector',
  phoneMain = 'phone',
  phoneSecondary = 'phoneSecondary',
  fax = 'fax',
  mobile = 'mobile',
  contactSchedule = 'contactSchedule',
  secondaryEmail = 'secondaryEmail',
  website = 'website',
}

export enum SignUpFormError {
  incomplete = 'incomplete',
  passwordsMismatch = 'passwordsMismatch',
  none = 'none',
}
export enum PublishMachineField {
  username = 'username',
  password = 'password',
  confirmPassword = 'confirmPassword',
  companyName = 'companyName',
  contactPerson = 'contactPerson',
  address = 'address',
  email = 'email',
  website = 'website',
  mobile = 'mobile',
  phone = 'phone',
  publishingType = 'publishingType',
  categoryName = 'categoryName',
  manufacturerName = 'manufacturerName',
  modelName = 'modelName',
  publishingTitle = 'publishingTitle',
  serialNumber = 'serialNumber',
  stockNumber = 'stockNumber',
  year = 'year',
  hours = 'hours',
  price = 'price',
  pricePerHour = 'pricePerHour',
  pricePerDay = 'pricePerDay',
  pricePerWeek = 'pricePerWeek',
  pricePerMonth = 'pricePerMonth',
  transportationRental = 'transportationRental',
  countryId = 'countryId',
  stateId = 'stateId',
  city = 'city',
  workName = 'workName',
  link = 'link',
  features = 'features',
  details = 'details',
  handledByCustomer = 'handledByCustomer',
}

export enum ValidationCodeStatus {
  unset = 'unset',
  invalid = 'invalid',
  expired = 'expired',
  valid = 'valid',
}

export enum PopperPlacement {
  topStart = 'top-start',
  top = 'top',
  topEnd = 'top-end',
  rightStart = 'right-start',
  right = 'right',
  rightEnd = 'right-end',
  leftStart = 'left-start',
  left = 'left',
  leftEnd = 'left-end',
  bottomStart = 'bottom-start',
  bottom = 'bottom',
  bottomEnd = 'bottom-end',
}