import { Theme } from '@mui/material/styles'
import { JustifyContent } from 'components/utils/enums'
import { pxToRem } from 'theme/typography'

export default function Snackbar(theme: Theme) {
  const { breakpoints, palette } = theme

  return {
    MuiSnackbar: {
      styleOverrides: {
        root: {
          bottom: 'unset',
          top: pxToRem(8),
          justifyContent: JustifyContent.center,
          [breakpoints.up('md')]: {
            bottom: 'unset',
            left: 'unset',
            right: 'unset',
          },
          '& .MuiSnackbarContent-root': {
            flexWrap: 'unset',
            fontWeight: 'bold',
            color: palette.common.white,
            '&.MuiDropzoneSnackbar-errorAlert': {
              backgroundColor: palette.error.dark,
            },
            '&.MuiDropzoneSnackbar-infoAlert': {
              backgroundColor: palette.info.dark,
            },
            '&.MuiDropzoneSnackbar-successAlert': {
              backgroundColor: palette.success.dark,
            },
          }
        },
      },
    },
  }
}