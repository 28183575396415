import { api } from 'api'
import { throwError } from 'redux/actions/alert'
import { STORED_SESSION_INITIAL_STATE } from 'redux/utils/constants'
import { Action, ActionResponseType } from 'redux/utils/enums'
import { formatApiParams, formatResponseParams, getApiHeaders, getStoredSession, getToken } from 'redux/utils/libs'
import { GetSignInResponse, GoogleProfileData, GoogleSignInParams, PasswordResetParams, SessionData, SignInParams, StoredSession } from 'redux/utils/session.types'
import { ServerResponse } from 'redux/utils/types'
import { STORAGE_KEY_USER_PROFILE } from 'utils/constants'
import { encodeContent, setIntoLocalStorage } from 'utils/libs'
import { ErrorProps } from 'utils/types'

/**
 * Verifies if the user is signed-in or not and if the token sesion is active or expired (when it exists).
 */
export const verifySession = () =>
  async(dispatch, getState) => {
    const {
      languageStore: {
        dictionary: { error: errorDictionary },
      },
    } = getState()

    try {
      dispatch({
        type: Action.SESSION_SIGN_IN_LOADING,
        payload: true,
      })

      const storedProfile: StoredSession = getStoredSession()
      const { isSignedIn } = storedProfile

      // Verify the token when the user has signed-in.
      if (isSignedIn) {
        // Get the User's Token.
        const token = getToken()
        const serializedToken = encodeContent(token)

        // Get the headers for the API call.
        const apiHeaders = getApiHeaders(isSignedIn, token)

        await api.get(`api/sessions.php/${serializedToken}`, apiHeaders)
      }

      dispatch({
        type: Action.SESSION_SIGN_IN,
        payload: storedProfile,
      })
    } catch(error) {
      const { errorCode, code: errorStatus, error: errorConsole } = error.response.data
      const errorMessage = errorDictionary[errorCode] || error.message

      const errorAlertProps: ErrorProps = {
        error,
        errorConsole,
        errorStatus,
        errorMessage,
      }

      // Reset the session into the localStorage.
      setIntoLocalStorage<StoredSession>(STORAGE_KEY_USER_PROFILE, STORED_SESSION_INITIAL_STATE)

      // Sign-out the User when an error occurs.
      dispatch({
        type: Action.SESSION_SIGN_OUT,
      })

      dispatch(throwError(errorAlertProps))
    } finally {
      dispatch({
        type: Action.SESSION_SIGN_IN_LOADING,
        payload: false,
      })
    }
  }

/**
 * Updates a User's password.
 * @param username The User's Username to be used for updating the password.
 * @param jsonParams The JSON Params which contains: "password".
 */
export const updatePassword = (username: string, jsonParams: PasswordResetParams) =>
  async(dispatch, getState) => {
    const {
      languageStore: {
        dictionary: { error: errorDictionary },
      },
    } = getState()

    try {
      dispatch({
        type: Action.SESSION_PASSWORD_RESET_LOADING,
        payload: true,
      })

      dispatch({
        type: Action.SESSION_PASSWORD_RESET,
        payload: null,
      })

      // Encode the username.
      const encodedUsername = encodeContent(username)

      // Format the API Params.
      const params = formatApiParams(jsonParams)

      // Reset User's Password.
      await api.put(`api/sessions.php/${encodedUsername}`, params, {
        data: params,
      })

      dispatch({
        type: Action.SESSION_PASSWORD_RESET,
        payload: true,
      })
    } catch(error) {
      const { errorCode, code: errorStatus, error: errorConsole } = error.response.data
      const errorMessage = errorDictionary[errorCode] || error.message

      const errorAlertProps: ErrorProps = {
        error,
        errorConsole,
        errorStatus,
        errorMessage,
      }

      dispatch({
        type: Action.SESSION_PASSWORD_RESET,
        payload: false,
      })

      dispatch(throwError(errorAlertProps))
    } finally {
      dispatch({
        type: Action.SESSION_PASSWORD_RESET_LOADING,
        payload: false,
      })
    }
  }

/**
 * Resets the varification for the User's Password Reset.
 */
export const resetUpdatePasswordAction = () =>
  async(dispatch) => {
    dispatch({
      type: Action.SESSION_PASSWORD_RESET,
      payload: null,
    })
  }

/**
 * Stores the Google Profile into the Store.
 * @param googleResponse The response data obtained when a user uses the Google Button for authentication.
 */
export const updateGoogleProfile = (googleResponse: GoogleProfileData) =>
  async(dispatch) => {
    dispatch({
      type: Action.SESSION_GOOGLE_PROFILE_UPDATE,
      payload: googleResponse,
    })
  }

/**
 * Signs In a user by using the Google API.
 * @param {Object} userData 
 */
 export const googleSignIn = (email: string, jsonParams: GoogleSignInParams) =>
  async(dispatch, getState) => {
    const {
      languageStore: {
        dictionary: { error: errorDictionary },
      },
    } = getState()

    try {
      dispatch({
        type: Action.SESSION_SIGN_IN_LOADING,
        payload: true,
      })

      dispatch({
        type: Action.SESSION_SIGN_IN,
        payload: STORED_SESSION_INITIAL_STATE,
      })

      // Encode the email.
      const encodedUsername = encodeContent(email)

      // Encode the params to be sent to the API.
      const params = formatApiParams(jsonParams)

      // Sign-in the User with the Gmail account.
      const serverResponse: ServerResponse<ActionResponseType.signIn> = (await api.post(`api/sessions.php/goauth/${encodedUsername}`, params, {
        data: params,
      })).data

      // Get the API response.
      const apiResponse: GetSignInResponse = serverResponse.response

      // Set the User Profile data.
      const profile: StoredSession = {
        isSignedIn: true,
        token: apiResponse.token,
        sessionData: formatResponseParams<SessionData>(apiResponse.context)
      }

      // Store the User's Profile data into the Local Storage.
      setIntoLocalStorage<StoredSession>(STORAGE_KEY_USER_PROFILE, profile)

      // Set the Sign-In values into the Redux State.
      dispatch({
        type: Action.SESSION_SIGN_IN,
        payload: profile,
      })
    } catch (error) {
      const { errorCode, code: errorStatus, error: errorConsole } = error.response.data
      const errorMessage = errorDictionary[errorCode] || error.message

      const errorAlertProps: ErrorProps = {
        error,
        errorConsole,
        errorStatus,
        errorMessage,
      }

      dispatch(throwError(errorAlertProps))
    } finally {
      dispatch({
        type: Action.SESSION_SIGN_IN_LOADING,
        payload: false,
      })
    }
  }

/**
 * Signs In a user by using the provided JSON Params: "Username" and "Password".
 * @param {Object} userData 
 */
export const signIn = (jsonParams: SignInParams) =>
  async(dispatch, getState) => {
    const {
      languageStore: {
        dictionary: { error: errorDictionary },
      },
    } = getState()

    try {
      dispatch({
        type: Action.SESSION_SIGN_IN_LOADING,
        payload: true,
      })

      dispatch({
        type: Action.SESSION_SIGN_IN,
        payload: {
          isSignedIn: false,
          token: null,
        }
      })

      // Encode the params to be sent to the API.
      const params = formatApiParams(jsonParams)

      // Sign-in the User.
      const serverResponse: ServerResponse<ActionResponseType.signIn> = (await api.post('api/sessions.php/', params, {
        data: params,
      })).data

      // Get the API response.
      const apiResponse: GetSignInResponse = serverResponse.response

      // Set the User Profile data.
      const profile: StoredSession = {
        isSignedIn: true,
        token: apiResponse.token,
        sessionData: formatResponseParams<SessionData>(apiResponse.context)
      }

      // Store the User's Profile data into the Local Storage.
      setIntoLocalStorage<StoredSession>(STORAGE_KEY_USER_PROFILE, profile)

      // Set the Sign-In values into the Redux State.
      dispatch({
        type: Action.SESSION_SIGN_IN,
        payload: profile,
      })
    } catch (error) {
      const { errorCode, code: errorStatus, error: errorConsole } = error.response.data
      const errorMessage = errorDictionary[errorCode] || error.message

      const errorAlertProps: ErrorProps = {
        error,
        errorConsole,
        errorStatus,
        errorMessage,
      }

      dispatch(throwError(errorAlertProps))
    } finally {
      dispatch({
        type: Action.SESSION_SIGN_IN_LOADING,
        payload: false,
      })
    }
  }

export const signOut = () =>
  async(dispatch, getState) => {
    const {
      languageStore: {
        dictionary: { error: errorDictionary },
      },
    } = getState()

    try {
      dispatch({
        type: Action.SESSION_SIGN_OUT_LOADING,
        payload: true,
      })

      // Get the User's token.
      const token = getToken()

      // Get the headers for the API call.
      const apiHeaders = getApiHeaders(true, token)

      // Sign-out the User.
      await api.delete('api/sessions.php/', apiHeaders)
    } catch (error) {
      const { errorCode, code: errorStatus, error: errorConsole } = error.response.data
      const errorMessage = errorDictionary[errorCode] || error.message

      const errorAlertProps: ErrorProps = {
        error,
        errorConsole,
        errorStatus,
        errorMessage,
      }

      dispatch(throwError(errorAlertProps))
    } finally {
      // Reset the session into the localStorage.
      setIntoLocalStorage<StoredSession>(STORAGE_KEY_USER_PROFILE, STORED_SESSION_INITIAL_STATE)

      // Reset the Session into Redux.
      dispatch({
        type: Action.SESSION_SIGN_OUT,
      })

      dispatch({
        type: Action.SESSION_SIGN_OUT_LOADING,
        payload: false,
      })
    }
  }