import { api } from 'api'
import { UserType } from 'pages/utils/enums'
import { throwError } from 'redux/actions/alert'
import { Action, ActionResponseType, UserSignupResponse } from 'redux/utils/enums'
import { formatApiParams } from 'redux/utils/libs'
import { ServerResponse } from 'redux/utils/types'
import { GetUsernameAvailabilityResponse, GetUsernameValidationResponse, SignupParams } from 'redux/utils/users.types'
import { encodeContent } from 'utils/libs'
import { ErrorProps } from 'utils/types'

/**
 * Verifies the provided "username" doesn't exist into the DB, which makes it available to be used.
 * @param username The username to be verified.
 */
export const checkUsernameAvailability = (username: string, isSignUpPage?: boolean) =>
  async(dispatch, getState) => {
    const {
      languageStore: {
        dictionary: { error: errorDictionary },
      },
    } = getState()

    try {
      dispatch({
        type: Action.USERS_USERNAME_VERIFICATION_LOADING,
        payload: true,
      })

      dispatch({
        type: Action.USERS_USERNAME_VERIFICATION,
        payload: null,
      })
  
      // Encode the username.
      const encodedUsername = encodeContent(username)
  
      // Trigger the API for Checking the Username Availability.
      const serverResponse: ServerResponse<ActionResponseType.usernameAvailability> = (await api.get(`api/users.php/availability/${encodedUsername}`)).data

      // Get the API response.
      const apiResponse: GetUsernameAvailabilityResponse = serverResponse.response

      // Update the Username Validation into the Redux State.
      dispatch({
        type: Action.USERS_USERNAME_VERIFICATION,
        payload: {
          isUsernameAvailable: !apiResponse.userExists,
          errorCode: undefined,
        }
      })
    } catch(error) {
      const { errorCode, code: errorStatus, error: errorConsole } = error.response.data
      const errorMessage = errorDictionary[errorCode] || error.message

      // Set that the username is not available because it already exists.
      if (errorStatus === 409 && isSignUpPage) {
        dispatch({
          type: Action.USERS_USERNAME_VERIFICATION,
          payload: {
            isUsernameAvailable: false,
            errorCode,
          }
        })
      } else {
        const errorAlertProps: ErrorProps = {
          error,
          errorConsole,
          errorStatus,
          errorMessage,
        }

        dispatch(throwError(errorAlertProps))
      }
    } finally {
      dispatch({
        type: Action.USERS_USERNAME_VERIFICATION_LOADING,
        payload: false,
      })
    }
  }

/**
 * Signs Up a user based on the provided form values.
 * @param jsonParams The JSON object which contains the params to be sent to the API.
 */
export const userSignup = (jsonParams: SignupParams) =>
  async(dispatch, getState) => {
    const {
      languageStore: {
        dictionary: { error: errorDictionary },
      },
    } = getState()

    try {
      dispatch({
        type: Action.USERS_SIGNUP_LOADING,
        payload: true,
      })

      // Encode the params to be sent to the API.
      const params = formatApiParams(jsonParams)

      // Create the new user.
      await api.post('api/users.php', params, {
        data: params,
      })

      const userSignupResponse: UserSignupResponse = jsonParams.userType === UserType.customer
        ? UserSignupResponse.registeredAndValidated
        : UserSignupResponse.registeredButNotValidated

      // Store the Session Data into Redux.
      dispatch({
        type: Action.USERS_SIGNUP,
        payload: userSignupResponse,
      })
    } catch (error) {
      const { errorCode, code: errorStatus, error: errorConsole } = error.response.data
      const errorMessage = errorDictionary[errorCode] || error.message

      const errorAlertProps: ErrorProps = {
        error,
        errorConsole,
        errorStatus,
        errorMessage,
      }

      dispatch(throwError(errorAlertProps))
    } finally {
      dispatch({
        type: Action.USERS_SIGNUP_LOADING,
        payload: false,
      })
    }
  }

/**
 * Validates the provided email confirming this is a valid email.
 * @param email The email to be validated.
 */
export const validateEmail = (email: string) =>
  async(dispatch) => {
    try {
      dispatch({
        type: Action.USERS_VALIDATE_EMAIL_LOADING,
        payload: true,
      })

      dispatch({
        type: Action.USERS_VALIDATE_EMAIL,
        payload: {
          isEmailValidated: null,
        },
      })
  
      // Encode the email.
      const encodedEmail = encodeContent(email)
  
      // Trigger the Email Validation API.
      await api.post(`api/users.php/validate/${encodedEmail}`)

      // Update the Email Validation into the Redux State.
      dispatch({
        type: Action.USERS_VALIDATE_EMAIL,
        payload: {
          isEmailValidated: true,
        },
      })
    } catch(error) {
      const { errorCode } = error.response.data

      // Set the email was not validated.
      dispatch({
        type: Action.USERS_VALIDATE_EMAIL,
        payload: {
          isEmailValidated: false,
          errorCode,
        }
      })
    } finally {
      dispatch({
        type: Action.USERS_VALIDATE_EMAIL_LOADING,
        payload: false,
      })
    }
  }

/**
 * Validates the provided username to make sure it is a valid one.
 * @param username The username to be validated.
 */
export const validateUsername = (username: string) =>
  async(dispatch, getState) => {
    const {
      languageStore: {
        dictionary: { error: errorDictionary },
      },
    } = getState()

    try {
      dispatch({
        type: Action.USERS_USERNAME_VALIDATION_LOADING,
        payload: true,
      })

      dispatch({
        type: Action.USERS_USERNAME_VALIDATION,
        payload: {},
      })
    
      // Encode the username.
      const encodedUsername = encodeContent(username)
    
      // Trigger the API for Validating the Username.
      const serverResponse: ServerResponse<ActionResponseType.usernameValidation> = (await api.get(`api/users.php/search/${encodedUsername}`)).data

      // Get the API response.
      const apiResponse: GetUsernameValidationResponse = serverResponse.response

      // Update the Username Validation into the Redux State.
      dispatch({
        type: Action.USERS_USERNAME_VALIDATION,
        payload: {
          email: apiResponse.email,
          name: apiResponse.name,
        }
      })
    } catch(error) {
      const { errorCode, code: errorStatus, error: errorConsole } = error.response.data
      const errorMessage = errorDictionary[errorCode] || error.message

      const errorAlertProps: ErrorProps = {
        error,
        errorConsole,
        errorStatus,
        errorMessage,
      }

      dispatch(throwError(errorAlertProps))
    } finally {
      dispatch({
        type: Action.USERS_USERNAME_VALIDATION_LOADING,
        payload: false,
      })
    }
  }

/**
 * Resets the props used for the username validation.
 * @returns 
 */
export const resetUsernameValidation = () =>
  async(dispatch) => {
    dispatch({
      type: Action.USERS_USERNAME_VALIDATION,
      payload: {},
    })
  }