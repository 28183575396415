import { Theme } from '@mui/material/styles'
import { AlignItems, JustifyContent } from 'components/utils/enums'
import { pxToRem } from 'theme/typography'

export default function Card(theme: Theme) {
  const { breakpoints, palette } = theme

  return {
    MuiCard: {
      styleOverrides: {
        root: {
          marginBottom: 0,
          width: '100%',
          overflow: 'unset',
          '&.MuiPaper-root.MuiPaper-outlined.MuiPaper-rounded': {
            marginBottom: pxToRem(8),
            '&:last-child': {
              marginBottom: 0,
            },
            '&.login-card': {
              margin: '0 auto',
              padding: pxToRem(16),
              display: 'flex',
              flexDirection: 'column',
              alignItems: AlignItems.baseline,
              justifyContent: JustifyContent.flexStart,
              width: pxToRem(240),
              [breakpoints.up('sm')]: {
                width: pxToRem(320),
              },
              [breakpoints.up('md')]: {
                width: pxToRem(500),
              },
            },
          },
          '& .MuiCardHeader-root': {
            padding: `${pxToRem(8)} ${pxToRem(16)}`,
            backgroundColor: palette.app.breadcrumbs.background,
            '& .MuiCardHeader-content': {
              '& .MuiCardHeader-title': {
                [breakpoints.up('md')]: {
                  fontSize: pxToRem(14),
                },
                marginBottom: 0,
                fontSize: pxToRem(12),
              },
            },
          },
          '& .MuiCardContent-root': {
            padding: `${pxToRem(8)} ${pxToRem(16)}`,
            display: 'flex',
            flexDirection: 'column',
            '&:last-child': {
              paddingBottom: pxToRem(8),
            },
          },
        },
      },
    },
  }
}