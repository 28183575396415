import { useTheme } from '@mui/material/styles'
import { DropdownSingle, DynamicRow, EmailInput, PasswordInput, PrimaryButton, StringInput } from 'components/FormFields'
import { PhoneNumberInput } from 'components/FormFields/PhoneNumberInput'
import { Row } from 'components/styledComponents'
import { AlignItems, JustifyContent } from 'components/utils/enums'
import { DropdownStructure } from 'components/utils/types'
import cloneDeep from 'lodash/cloneDeep'
import { SignUpFormError, SignupFormField } from 'pages/utils/enums'
import { SignupForm } from 'pages/utils/types'
import React, { FC, Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { getCountries } from 'redux/actions/countries'
import { getStates } from 'redux/actions/states'
import { checkUsernameAvailability } from 'redux/actions/users'
import { CountriesResponse } from 'redux/utils/countries.types'
import { Language } from 'redux/utils/enums'
import { LanguageDictionary } from 'redux/utils/language.types'
import { GoogleProfileData } from 'redux/utils/session.types'
import { GetStatesParams, StatesResponse } from 'redux/utils/states.types'
import { ReduxStore } from 'redux/utils/types'
import { pxToRem } from 'theme/typography'

type Props = {
  countries?: CountriesResponse[],
  countriesLoading?: boolean,
  dictionary: LanguageDictionary
  isUsernameAvailable: boolean
  googleProfile: GoogleProfileData
  language: Language
  states?: StatesResponse[],
  statesLoading?: boolean,
  key?: string
  formValues: SignupForm
  disableSubmitButton?: boolean
  dispatchCheckUsernameAvailability: (username: string) => void
  dispatchGetCountries: () => void
  dispatchGetStates: (params: GetStatesParams) => void
  onChange: (newFormValues: SignupForm) => void
  onSubmit: (newFormValues: SignupForm, errorType: SignUpFormError) => void
}

const UnconnectedSignUpForm: FC<Props> = ({
  countries,
  countriesLoading,
  dictionary: {
    signUp: signUpDictionary,
    shared: sharedDictionary,
  },
  isUsernameAvailable,
  googleProfile,
  language,
  states,
  statesLoading,
  key,
  formValues,
  disableSubmitButton,
  dispatchGetCountries,
  dispatchGetStates,
  dispatchCheckUsernameAvailability,
  onChange,
  onSubmit,
}) => {
  const { palette } = useTheme()
  const [isMismatchError, setIsMismatchError] = useState<boolean>()
  const [countriesOptions, setCountriesOptions] = useState<DropdownStructure[]>([])
  const [statesOptions, setStatesOptions] = useState<DropdownStructure[]>([])

  const adornmentBgColor: string = palette.grey[300]

  /**
   * Verifies if all the required fields have been populated and returns the result.
   * @param formBody The whole form with its fields.
   */
  const handleEvaluateForm = (formBody: SignupForm): {
    isFormComplete: boolean
    formUpdated: SignupForm
  } => {
    let isFormComplete = true
    const newFormValues = cloneDeep(formBody)

    Object.entries(newFormValues).forEach(([field, structure]) => {
      if (structure.required && (!structure.value || structure.value === -1)) {
        newFormValues[field].valid = false
        newFormValues[field].touched = true
        isFormComplete = false
      } else {
        newFormValues[field].valid = true
        newFormValues[field].touched = true
      }
    })

    // Update the form changes due the evaluation.
    onChange(newFormValues)

    return {
      isFormComplete,
      formUpdated: newFormValues,
    }
  }

  /**
   * Compares the Password with its Confirmation to validate both are the same.
   * @param password The provided password.
   * @param confirmPassword The value that need to match with the password to make sure the user provided the desired password.
   */
  const handleValidatePasswords = (password: string, confirmPassword: string): void => {
    const passwordMismatch: boolean = password !== confirmPassword

    setIsMismatchError(passwordMismatch)

    // Update the "valid" prop for the "Confirm Password" field.
    const newFormValues: SignupForm = {
      ...formValues,
      confirmPassword: {
        ...formValues.confirmPassword,
        value: confirmPassword,
        valid: !passwordMismatch,
      },
    }

    // Update the values for the form.
    onChange(newFormValues)
  }

  /**
   * Updates a value into the form based on the provided field name.
   * @param fieldName The field to be updated within the form.
   */
  const handleFieldChange = (fieldName: SignupFormField) => (newValue: string): void => {
    // Update the value for the provided fieldname.
    const newFormValues: SignupForm = {
      ...formValues,
      [fieldName]: {
        ...formValues[fieldName],
        value: newValue,
      },
    }

    // Compare the password with its confirmation when the updated field is the "Confirm Password".
    if (fieldName === SignupFormField.confirmPassword) {
      handleValidatePasswords(formValues.password.value, newValue)
      return
    }

    // Update the values for the form.
    onChange(newFormValues)
  }

  /**
   * Verifies if a provided username is in use or if it is available for sign-up.
   * @param username The username to be evaluated.
   */
   const handleValidateUsername = (username: string) => (_hasErrors?: boolean) => {
    // Trigger the evaluation only when the user provided the username manually.
    if (googleProfile?.email !== username) {
      dispatchCheckUsernameAvailability(username)
    }
  }

  /**
   * Updates a value into the form based on the provided field name.
   * @param fieldName The field to be updated within the form.
   */
  const handleDropdownChange = (fieldName: SignupFormField) => (newValue: string): void => {
    // Translate the value to number since the Country and State dropdowns use a numeric ID.
    const numericValue = Number(newValue)

    // Update the value for the provided fieldname.
    const newFormValues: SignupForm = {
      ...formValues,
      [fieldName]: {
        ...formValues[fieldName],
        value: numericValue,
      },
    }

    // Reset the value for the State ID every time the Country ID value changes.
    if (fieldName === SignupFormField.countryId) {
      newFormValues.stateId.value = -1
    }

    // Update the values for the form.
    onChange(newFormValues)
  }

  /**
   * Triggers the passwords validation when the user blurs from the "Confirm Password" field.
   * @param password The provided password.
   * @param confirmPassword The value that need to match with the password to make sure the user provided the desired password.
   */
  const handleComparePasswords = (password: string, confirmPassword: string) => (): void => {
    handleValidatePasswords(password, confirmPassword)
  }

  /**
   * Evaluates the form to make sure all the required fields have been provided. If any required field is missing, the form won't be submitted.
   * @param formBody 
   */
  const handleSubmitForm = (formBody: SignupForm) => (): void => {
    // Verify all the required fields have been populated.
    const { isFormComplete, formUpdated } = handleEvaluateForm(formBody)

    // Display an error if the form isn't complete.
    if (!isFormComplete) {
      // Return the form with a flag letting know the form is incomplete.
      onSubmit(formUpdated, SignUpFormError.incomplete)

      return
    }

    // Make sure "Password" and "Confirm Password" match.
    const passwordsMatch = isFormComplete && formBody.password.value === formBody.confirmPassword.value

    if (!passwordsMatch) {
      // Return the form with a flag letting know the form is complete but the passwords mismatch.
      onSubmit(formUpdated, SignUpFormError.passwordsMismatch)

      return
    }

    // Return the form with a flag letting know the form is complete and good to go.
    onSubmit(formUpdated, SignUpFormError.none)
  }

  useEffect(() => {
    if (!countries) {
      dispatchGetCountries()
    }
  }, [])

  useEffect(() => {
    if (countriesLoading) {
      return
    }

    if (countries?.length && !countriesOptions.length) {
      const countriesList: DropdownStructure[] = countries.map(country => ({
        label: country.country[language],
        value: country.countryId.toString(),
      }))

      setCountriesOptions(countriesList)
    }
  }, [countries, countriesLoading])

  useEffect(() => {
    const { countryId: {
      value: countryIdValue
    }  } = formValues

    if (countryIdValue > -1) {
      const params: GetStatesParams = {
        countryId: countryIdValue,
      }
  
      dispatchGetStates(params)
    }
  }, [formValues.countryId.value])

  useEffect(() => {
    if (statesLoading) {
      return
    }

    if (states?.length) {
      const statesList: DropdownStructure[] = states.map(state => ({
        label: state.state[language],
        value: state.stateId.toString(),
      }))

      setStatesOptions(statesList)
    }
  }, [states, statesLoading])

  useEffect(() => {
    if (googleProfile && isUsernameAvailable) {
      // Update the value for the provided fieldname.
      const newFormValues: SignupForm = {
        ...formValues,
        username: {
          ...formValues.username,
          value: googleProfile.email,
        },
        name: {
          ...formValues.name,
          value: googleProfile.name,
        }
      }

      // Update the values for the form.
      onChange(newFormValues)
    }
  }, [googleProfile, isUsernameAvailable])

  return (
    <Fragment key={key}>
      <EmailInput
        id={SignupFormField.username}
        label={signUpDictionary.fieldUsername}
        placeholder={signUpDictionary.placeholderUsername}
        value={formValues.username.value}
        fullWidth
        required={formValues.username.required}
        isMissing={!formValues.username.valid && !!formValues.username.touched}
        disabled={!!googleProfile && isUsernameAvailable}
        onChange={handleFieldChange(SignupFormField.username)}
        onBlur={handleValidateUsername(formValues.username.value)}
      />
      <DynamicRow
        alignItems={AlignItems.baseline}
        groupedByColumns={{ xs: '1', md: '2' }}
        gridRowGap={{ xs: '0' }}
        gridColumnGap={{ xs: '0', md: pxToRem(8) }}
      >
        <PasswordInput
          id={SignupFormField.password}
          label={signUpDictionary.fieldPassword}
          placeholder={signUpDictionary.placeholderPassword}
          value={formValues.password.value}
          adornmentBgColor={adornmentBgColor}
          fullWidth
          required={formValues.password.required}
          isMissing={!formValues.password.valid && !!formValues.password.touched}
          generatePassword
          onChange={handleFieldChange(SignupFormField.password)}
        />
        <PasswordInput
          id={SignupFormField.confirmPassword}
          label={signUpDictionary.fieldConfirmPassword}
          placeholder={signUpDictionary.placeholderConfirmPassword}
          value={formValues.confirmPassword.value}
          adornmentBgColor={adornmentBgColor}
          fullWidth
          required={formValues.confirmPassword.required}
          isMissing={!formValues.confirmPassword.valid && !!formValues.confirmPassword.touched}
          isMismatchError={isMismatchError}
          onChange={handleFieldChange(SignupFormField.confirmPassword)}
          onBlur={handleComparePasswords(formValues.password.value, formValues.confirmPassword.value)}
        />
      </DynamicRow>
      <StringInput
        id={SignupFormField.name}
        label={signUpDictionary.fieldName}
        placeholder={signUpDictionary.placeholderName}
        value={formValues.name.value}
        fullWidth
        required={formValues.name.required}
        isMissing={!formValues.name.valid && !!formValues.name.touched}
        onChange={handleFieldChange(SignupFormField.name)}
      />
      <DynamicRow
        alignItems={AlignItems.baseline}
        groupedByColumns={{ xs: '1', md: '3' }}
        gridRowGap={{ xs: '0' }}
        gridColumnGap={{ xs: '0', md: pxToRem(8) }}
      >
        <DropdownSingle
          id={SignupFormField.countryId}
          label={signUpDictionary.fieldCountry}
          nonValueLabel={signUpDictionary.placeholderCountry}
          options={countriesOptions}
          value={formValues.countryId.value.toString()}
          fullWidth
          required={formValues.countryId.required}
          isMissing={!formValues.countryId.valid && !!formValues.countryId.touched}
          onChange={handleDropdownChange(SignupFormField.countryId)}
        />
        <DropdownSingle
          id={SignupFormField.stateId}
          label={signUpDictionary.fieldState}
          nonValueLabel={signUpDictionary.placeholderState}
          options={statesOptions}
          value={formValues.stateId.value.toString()}
          fullWidth
          required={formValues.stateId.required}
          isMissing={!formValues.stateId.valid && !!formValues.stateId.touched}
          disabled={formValues.countryId.value === -1}
          onChange={handleDropdownChange(SignupFormField.stateId)}
        />
        <StringInput
          id={SignupFormField.city}
          label={signUpDictionary.fieldCity}
          placeholder={signUpDictionary.placeholderCity}
          value={formValues.city.value}
          fullWidth
          required={formValues.city.required}
          isMissing={!formValues.city.valid && !!formValues.city.touched}
          onChange={handleFieldChange(SignupFormField.city)}
        />
      </DynamicRow>
      <DynamicRow
        alignItems={AlignItems.baseline}
        groupedByColumns={{ xs: '1', md: '2' }}
        gridRowGap={{ xs: '0' }}
        gridColumnGap={{ xs: '0', md: pxToRem(8) }}
      >
        <PhoneNumberInput
          label={signUpDictionary.fieldMobile}
          value={formValues.mobile.value}
          fullWidth
          required={formValues.mobile.required}
          isMissing={!formValues.mobile.valid && !!formValues.mobile.touched}
          onChange={handleFieldChange(SignupFormField.mobile)}
        />
        <PhoneNumberInput
          label={signUpDictionary.fieldPhoneMain}
          value={formValues.phoneMain.value}
          fullWidth
          required={formValues.phoneMain.required}
          isMissing={!formValues.phoneMain.valid && !!formValues.phoneMain.touched}
          onChange={handleFieldChange(SignupFormField.phoneMain)}
        />
      </DynamicRow>
      <EmailInput
        id={SignupFormField.secondaryEmail}
        label={signUpDictionary.fieldSecondaryEmail}
        placeholder={signUpDictionary.placeholderSecondaryEmail}
        value={formValues.secondaryEmail.value}
        fullWidth
        required={formValues.secondaryEmail.required}
        onChange={handleFieldChange(SignupFormField.secondaryEmail)}
      />
      <Row marginTop={pxToRem(16)} justifyContent={JustifyContent.center}>
        <PrimaryButton
          label={sharedDictionary.createMyAccount}
          loading={disableSubmitButton}
          onClick={handleSubmitForm(formValues)}
        />
      </Row>
    </Fragment>
  )
}

const mapStateToProps = ({ countriesStore, languageStore, sessionStore, statesStore, usersStore }: ReduxStore) => {
  const { countries, countriesLoading } = countriesStore
  const { dictionary, language } = languageStore
  const { isSignedIn, googleProfile } = sessionStore
  const { states, statesLoading } = statesStore
  const { isUsernameAvailable } = usersStore

  return {
    countries,
    countriesLoading,
    dictionary,
    language,
    isSignedIn,
    isUsernameAvailable,
    googleProfile,
    states,
    statesLoading,
  }
}

const mapDispatchToProps = (dispatch) => ({
  dispatchCheckUsernameAvailability: (username: string) => dispatch(checkUsernameAvailability(username, true)),
  dispatchGetCountries: () => dispatch(getCountries()),
  dispatchGetStates: (params: GetStatesParams) => dispatch(getStates(params)),
})

const SignUpForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedSignUpForm)

export default SignUpForm
