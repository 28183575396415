export enum Action {
  // Language Actions
  LANGUAGE_UPDATE = 'LANGUAGE_UPDATE',
  DICTIONARY_UPDATE = 'DICTIONARY_UPDATE',
  // Location Actions
  LOCATION_UPDATE = 'LOCATION_UPDATE',
  // Alert Actions
  ALERT_SHOW = 'ALERT_SHOW',
  ALERT_HIDE = 'ALERT_HIDE',
  // Confirm Actions
  CONFIRM_SHOW = 'CONFIRM_SHOW',
  CONFIRM_HIDE = 'CONFIRM_HIDE',
  // Countries Actions
  COUNTRIES_LOADING = 'COUNTRIES_LOADING',
  COUNTRIES_READ = 'COUNTRIES_READ',
  // States Actions
  STATES_LOADING = 'STATES_LOADING',
  STATES_READ = 'STATES_READ',
  // Machinery Actions
  CATEGORIES_LOADING = 'CATEGORIES_LOADING',
  CATEGORIES_READ = 'CATEGORIES_READ',
  CATEGORIES_MAP_UPDATE = 'CATEGORIES_MAP_UPDATE',
  MANUFACTURERS_LOADING = 'MANUFACTURERS_LOADING',
  MANUFACTURERS_READ = 'MANUFACTURERS_READ',
  MANUFACTURERS_MAP_UPDATE = 'MANUFACTURERS_MAP_UPDATE',
  MODELS_LOADING = 'MODELS_LOADING',
  MODELS_READ = 'MODELS_READ',
  MODELS_MAP_UPDATE = 'MODELS_MAP_UPDATE',
  MACHINES_LOADING = 'MACHINES_LOADING',
  MACHINES_READ = 'MACHINES_READ',
  MACHINES_ERROR = 'MACHINES_ERROR',
  MACHINE_DETAILS_LOADING = 'MACHINE_DETAILS_LOADING',
  MACHINE_DETAILS_READ = 'MACHINE_DETAILS_READ',
  MACHINE_PUBLISHING_LOADING = 'MACHINE_PUBLISHING_LOADING',
  MACHINES_CAROUSEL_LOADING = 'MACHINES_CAROUSEL_LOADING',
  MACHINES_CAROUSEL_READ = 'MACHINES_CAROUSEL_READ',
  // Quotations Actions
  QUOTATION_DATA_LOADING = 'QUOTATION_DATA_LOADING',
  QUOTATION_DATA_READ = 'QUOTATION_DATA_READ',
  QUOTATION_DESTINATIONS_LOADING = 'QUOTATION_DESTINATIONS_LOADING',
  QUOTATION_DESTINATIONS_READ = 'QUOTATION_DESTINATIONS_READ',
  // Timezone Actions
  TIMEZONE_UPDATE = 'TIMEZONE_UPDATE',
  // Session Actions
  SESSION_LOADING = 'SESSION_LOADING',
  SESSION_GOOGLE_PROFILE_UPDATE = 'SESSION_GOOGLE_PROFILE_UPDATE',
  SESSION_PASSWORD_RESET = 'SESSION_PASSWORD_RESET',
  SESSION_PASSWORD_RESET_LOADING = 'SESSION_PASSWORD_RESET_LOADING',
  SESSION_SIGN_IN = 'SESSION_SIGN_IN',
  SESSION_SIGN_IN_LOADING = 'SESSION_SIGN_IN_LOADING',
  SESSION_SIGN_OUT = 'SESSION_SIGN_OUT',
  SESSION_SIGN_OUT_LOADING = 'SESSION_SIGN_OUT_LOADING',
  // Users Actions
  USERS_READ = 'USERS_READ',
  USERS_READ_LOADING = 'USERS_READ_LOADING',
  USERS_SIGNUP = 'USERS_SIGNUP',
  USERS_SIGNUP_LOADING = 'USERS_SIGNUP_LOADING',
  USERS_USERNAME_VALIDATION = 'USERS_USERNAME_VALIDATION',
  USERS_USERNAME_VALIDATION_LOADING = 'USERS_USERNAME_VALIDATION_LOADING',
  USERS_USERNAME_VERIFICATION = 'USERS_USERNAME_VERIFICATION',
  USERS_USERNAME_VERIFICATION_LOADING = 'USERS_USERNAME_VERIFICATION_LOADING',
  USERS_VALIDATE_EMAIL = 'USERS_VALIDATE_EMAIL',
  USERS_VALIDATE_EMAIL_LOADING = 'USERS_VALIDATE_EMAIL_LOADING',
  // Requests Actions
  REQUESTS_READ = 'REQUESTS_READ',
  REQUESTS_READ_LOADING = 'REQUESTS_LOADING',
  REQUEST_DETAILS_READ = 'REQUEST_DETAILS_READ',
  REQUEST_DETAILS_READ_LOADING = 'REQUEST_DETAILS_READ_LOADING',
}

export enum Language {
  en = 'en',
  es = 'es',
}

export enum SortDirection {
  asc = 'asc',
  desc = 'desc',
}

export enum ActionResponseType {
  categoriesList = 'categoriesList',
  manufacturersList = 'manufacturersList',
  modelsList = 'modelsList',
  machinesList = 'machinesList',
  machineDetails = 'machineDetails',
  machinesCarousel = 'machinesCarousel',
  quotationData = 'quotationData',
  quotationDestinations = 'quotationDestinations',
  usernameAvailability = 'usernameAvailability',
  usernameValidation = 'usernameValidation',
  emailValidation = 'emailValidation',
  signIn = 'signIn',
  countriesList = 'countriesList',
  statesList = 'statesList',
  requestsList = 'requestsList',
  requestDetails = 'requestDetails',
  uploadPhotos = 'uploadPhotos',
}

export enum UserSignupResponse {
  unset = 'unset',
  error = 'error',
  registeredButNotValidated = 'registeredButNotValidated',
  registeredAndValidated = 'registeredAndValidated',
}

export enum RequestStatus {
  pending = 'pending',
  solved = 'solved',
  customer = 'customer',
  unsolved = 'unsolved',
}

export enum RequestsFilter {
  all = 'all',
  pending = 'pending',
  solved = 'solved',
  customer = 'customer',
  unsolved = 'unsolved',
}

export enum RequestsSortBy {
  requestId = 'requestId',
  userCompanyName = 'userCompanyName',
  userContactPerson = 'userContactPerson',
  requestStatus = 'requestStatus',
  requestDate = 'requestDate',
}

export enum RequestType {
  sale = 'sale',
  rent = 'rent',
  spareParts = 'spareParts'
}

export enum PublishingType {
  sale = 'sale',
  rent = 'rent',
  both = 'both',
  draft = 'draft',
}