import { Theme } from '@mui/material/styles'
import { AlignItems, JustifyContent } from 'components/utils/enums'
import { pxToRem } from 'theme/typography'

export default function DropzoneArea(theme: Theme) {
  const { breakpoints, palette } = theme

  return {
    MuiDropzoneArea: {
      styleOverrides: {
        root: {
          padding: pxToRem(8),
          overflow: 'scroll',
          display: 'flex',
          flexDirection: 'column',
          alignItems: AlignItems.center,
          justifyContent: JustifyContent.flexStart,
          color: palette.grey[500],
          '& .MuiSvgIcon-root': {
            color: 'inherit',
          },
          '& .MuiDropzonePreviewList-root': {
            marginTop: 0,
            marginLeft: 0,
            width: '100%',
            '& .MuiDropzonePreviewList-imageContainer': {
              paddingTop: pxToRem(16),
              paddingBottom: pxToRem(8),
              paddingLeft: pxToRem(32),
              maxWidth: 'unset',
              flexBasis: 'unset',
              position: 'relative',
              '& .MuiDropzonePreviewList-image': {
                width: pxToRem(150),
                height: pxToRem(100),
                objectFit: 'cover',
              },
              '& .MuiDropzonePreviewList-removeButton': {
                position: 'absolute',
                opacity: 1,
                top: 0,
                right: 0,
                width: pxToRem(36),
                height: pxToRem(36),
                fontSize: pxToRem(20),
                border: `1px solid ${palette.app.border}`,
                color: palette.common.white,
                backgroundColor: palette.error.main,
                [breakpoints.up('md')]: {
                  top: pxToRem(8),
                  width: pxToRem(24),
                  height: pxToRem(24),
                  minHeight: 'unset',
                  fontSize: pxToRem(12),
                  lineHeight: 'unset',
                },
                '& .MuiSvgIcon-root': {
                  fontSize: 'inherit',
                },
              },
            },
          },
        },
      },
    },
  }
}