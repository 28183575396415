import { UserType } from 'pages/utils/enums'
import { PublishMachineForm, SignupForm, SignupFormProps, UserFormProps } from 'pages/utils/types'
import { PublishingType } from 'redux/utils/enums'

export const ADORNMENT_BG_COLOR: string = '#dfe3e8'

export const SIGNUP_FORM_DEFAULT: SignupForm = {
  userType: {
    value: UserType.notValidated,
    required: true,
  },
  username: {
    value: '',
    required: true,
  },
  password: {
    value: '',
    required: true,
  },
  confirmPassword: {
    value: '',
    required: true,
  },
  name: {
    value: '',
    required: true,
  },
  countryId: {
    value: -1,
    required: true,
  },
  stateId: {
    value: -1,
    required: true,
  },
  city: {
    value: '',
    required: true,
  },
  mobile: {
    value: '',
    required: true,
  },
  phoneMain: {
    value: '',
    required: false,
  },
  secondaryEmail: {
    value: '',
    required: false,
  },
}

export const SIGNUP_FORM_PROPS_DEFAULT: SignupFormProps = {
  userType: UserType.notValidated,
  username: '',
  password: '',
  confirmPassword: '',
  name: '',
  countryId: -1,
  stateId: -1,
  city: '',
  mobile: '',
  phoneMain: '',
  secondaryEmail: '',
}

export const USER_FORM_DEFAULT: UserFormProps = {
  userType: UserType.notValidated,
  username: '',
  password: '',
  confirmPassword: '',
  companyName: '',
  contactPerson: '',
  countryId: -1,
  stateId: -1,
  city: '',
  address: '',
  managingDirector: '',
  phoneMain: '',
  phoneSecondary: '',
  fax: '',
  mobile: '',
  contactSchedule: '',
  secondaryEmail: '',
  website: '',
}

export const PUBLISH_MACHINE_FORM: PublishMachineForm = {
  username: {
    value: '',
    required: true,
  },
  password: {
    value: '',
    required: true,
  },
  confirmPassword: {
    value: '',
    required: true,
  },
  publishingType: {
    value: PublishingType.draft,
    required: true,
  },
  companyName: {
    value: '',
    required: false,
  },
  contactPerson: {
    value: '',
    required: true,
  },
  address: {
    value: '',
    required: false,
  },
  email: {
    value: '',
    required: true,
  },
  website: {
    value: '',
    required: false,
  },
  mobile: {
    value: '',
    required: true,
  },
  phone: {
    value: '',
    required: false,
  },
  categoryName: {
    value: '-1',
    required: true,
  },
  manufacturerName: {
    value: '-1',
    required: true,
  },
  modelName: {
    value: '-1',
    required: true,
  },
  serialNumber: {
    value: '',
    required: false,
  },
  stockNumber: {
    value: '',
    required: false,
  },
  year: {
    value: -1,
    required: false,
  },
  hours: {
    value: '',
    required: false,
  },
  price: {
    value: '',
    required: true,
  },
  pricePerHour: {
    value: '',
    required: false,
  },
  pricePerDay: {
    value: '',
    required: false,
  },
  pricePerWeek: {
    value: '',
    required: false,
  },
  pricePerMonth: {
    value: '',
    required: false,
  },
  transportationRental: {
    value: '',
    required: false,
  },
  countryId: {
    value: -1,
    required: true,
  },
  stateId: {
    value: -1,
    required: true,
  },
  city: {
    value: '',
    required: true,
  },
  link: {
    value: '',
    required: false,
  },
  features: {
    value: '',
    required: true,
  },
  details: {
    value: '',
    required: false,
  },
  handledByCustomer: {
    value: false,
    required: false,
  },
  publishingTitle: {
    value: '',
    required: true,
  },
}